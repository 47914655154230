import { sign } from "crypto";
import { IResponse, IResponseData } from "../common/response";
import { getWithAuth } from "../utils/baseFetch";
import { City } from "./city";
import { CityDto } from "./dto/cityDto";

export class CityRepository {
    public async getAllCities(showDeleted: boolean, signal?: AbortSignal): Promise<IResponseData<City[]>> {
        try {
            const response = await getWithAuth<CityDto[]>(
                "/api/city/getAll",
                { showDeleted: `${showDeleted}` },
                signal
            );

            if (!response.ok) return { ok: false, data: [], message: response.message };

            return {
                ok: true,
                data: response.data?.map(
                    (val) =>
                        <City>{ id: val.id, label: { en: val.enName, 한국어: val.krName }, isDeleted: val.isDeleted }
                ),
                message: "",
            };
        } catch (ex) {
            console.log(ex);
            return { ok: false, message: (ex as Error).message };
        }
    }
}

const instance = new CityRepository();

export { instance as cityRepository };
