import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const variants = {
    languageSelector: {
        button: {},
        list: {
            bg: "white",
        },
        item: {
            bg: "",
            _hover: {
                bg: "quiz_purple.100",
            },
            color: "quiz_purple.600",
        },
    },
    mainMenu: {
        button: {
            
        },
        list: {
            bg: "white",
        },
        item: {
            bg: "",
            _hover: {
                bg: "quiz_purple.100",
            },
            color: "quiz_purple.600",
        },
    },
};

export const menuCustomTheme = defineMultiStyleConfig({ variants });
