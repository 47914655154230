import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    Center,
    ChakraProvider,
    FormControl,
    FormErrorMessage,
    Input,
    Spacer,
    VStack,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authRepository, AuthRepository } from "../domain/auth/authRepository";
import { customTheme } from "../theme/customTheme";

interface IState {
    login?: string;
    pwd?: string;
    isInvalid?: boolean;
}

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState<IState>({
        login: "",
        pwd: "",
        isInvalid: false,
    });

    const handleLoginChange = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setState({ ...state, login: value });
    };

    const handlePwdChange = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setState({ ...state, pwd: value });
    };

    const onSignIn = async () => {
        const response = await authRepository.SignIn(state?.login, state?.pwd);
        if (!response.ok) {
            setState({ ...state, isInvalid: true });
            return;
        }

        setState({ ...state, isInvalid: false });
        navigate("/admin");
    };

    useEffect(() => {
        setState({
            login: "",
            pwd: "",
            isInvalid: false,
        });
    }, []);

    return (
        <ChakraProvider theme={customTheme}>
            <Center w="100%" minH={600}>
                <Card minW="sm">
                    <CardBody>
                        <VStack>
                            <Input value={state?.login} onChange={handleLoginChange} placeholder="Login" />
                            <Input
                                type="password"
                                value={state?.pwd}
                                onChange={handlePwdChange}
                                placeholder="Password"
                            />
                            {state?.isInvalid && <Alert status="error">Invalid login or password</Alert>}
                        </VStack>
                    </CardBody>
                    <CardFooter justify="center">
                        <Button onClick={onSignIn}>Sign In</Button>
                    </CardFooter>
                </Card>
            </Center>
        </ChakraProvider>
    );
};
