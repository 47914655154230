import { AboutContainer } from "../about/aboutContainer";
import Hero from "../hero/hero";
import { LastEventsContainer } from "../lastEvents/lastEventsContainer";
import UpcomingEventsContainer from "../upcoming-events/upcomingEventsContainer";

const HomePageContainer = () => {
    return (
        <>
            <Hero />
            <UpcomingEventsContainer />
            <AboutContainer />
            <LastEventsContainer />
        </>
    );
};

export default HomePageContainer;
