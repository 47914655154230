import { BookingType } from "./bookingType";
import { BookingDto } from "./dto/bookingDto";

export type Booking = {
    id: string;
    eventId: string;
    teamName: string;
    teamLeaderName: string;
    phoneNumber: string;
    email: string;
    dateOfBooking: Date;
    teammatesCount: number;
    isFirstGame: boolean;
    hasCertificate: boolean;
    comment: string;
    bookingType: BookingType;
    eventVersion: string;
    isDeleted: boolean;
};

export const getBookingFromDto = (dto: BookingDto): Booking => {
    return {
        id: dto.id,
        teamName: dto.teamName,
        teamLeaderName: dto.teamLeaderName,
        phoneNumber: dto.phoneNumber,
        email: dto.email,
        comment: dto.comment,
        teammatesCount: dto.teammatesCount,
        isFirstGame: dto.isFirstGame,
        hasCertificate: dto.hasBonusCertificate,
        bookingType: dto.bookingType,
        eventVersion: dto.eventVersion,
        eventId: dto.eventId,
        dateOfBooking: new Date(dto.dateOfBooking),
        isDeleted: dto.isDeleted,
    };
};
