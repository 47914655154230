interface IToken {
    token: string;
    expireDate: string; // UTC
}

export class AuthToken {
    private expirationPeriod = 50;
    private storageKey = "jwt_token";

    private getExpirationDate = () => {
        const expire = new Date();
        expire.setMinutes(expire.getMinutes() + this.expirationPeriod);

        return expire;
    };

    public renewToken = (response: Response) => {
        const newToken = response.headers.get("x-token");
        if (newToken) {
            localStorage.setItem(
                this.storageKey,
                JSON.stringify({ token: newToken, expireDate: this.getExpirationDate().toISOString() })
            );
        }
    };

    public getToken = () => {
        const tokenStr = localStorage.getItem(this.storageKey);
        if (tokenStr === null) return null;

        const tokenObj = JSON.parse(tokenStr) as IToken;

        if (new Date(tokenObj.expireDate).getTime() < Date.now()) localStorage.removeItem(this.storageKey);

        return tokenObj.token;
    };

    public setToken = (token: string) => {
        if (token)
            localStorage.setItem(
                this.storageKey,
                JSON.stringify({ token: token, expireDate: this.getExpirationDate().toISOString() })
            );
    };
}

const instance = new AuthToken();
export { instance as authToken };
