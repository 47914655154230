import { sign } from "crypto";
import { IResponse, IResponseData } from "../common/response";
import { getWithAuth, postWithAuth } from "../utils/baseFetch";
import { PlaceDto } from "./dto/placeDto";
import { getPlaceFromDto, Place } from "./place";

export class PlaceRepository {
    public async getPlacesByCity(
        cityId: string,
        showDeleted: boolean,
        signal?: AbortSignal
    ): Promise<IResponseData<Place[]>> {
        try {
            if (cityId.length === 0) return { ok: false, data: [], message: "" };

            const response = await getWithAuth<PlaceDto[]>(
                "/api/place/getByCityId",
                { cityId: cityId, showDeleted: `${showDeleted}` },
                signal
            );

            if (!response.ok) return { ok: false, data: [], message: response.message };

            return { ok: true, data: response.data?.map((x) => getPlaceFromDto(x)), message: "" };
        } catch (ex) {
            if (ex instanceof Error && ex.name === "AbortError") {
                console.log(ex);
                return { ok: false, data: [], message: (ex as Error).message };
            } else {
                console.error(ex);
                return { ok: false, data: [], message: (ex as Error).message };
            }
        }
    }


}

const instance = new PlaceRepository();

export { instance as placeRepository };
