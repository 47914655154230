import { Box, Flex, VStack, Text, HStack, useBreakpoint, Image, Button, Center } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Languages } from "../../intl-languages";
import { LanguageContext } from "../../context/languageContext";
import { intl, IntlKey } from "../../intl-languages";
import { FiChevronRight, FiFacebook, FiInstagram } from "react-icons/fi";
import { RiCopyrightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { isMobileScreen } from "../../common/breakPoints";
import { ContactInfo } from "../../common/contactInfo";
import { RulesModal } from "../about/rulesModal";
import { useDisclosure } from "@chakra-ui/react-use-disclosure";

export const FooterContainer = () => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const { isOpen, onOpen, onClose } = useDisclosure();

    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);

    return (
        <>
            <Box
                w="100%"
                position="absolute"
                bottom={0}
                backgroundImage="url('/assets/footer_bg.png')"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                bgColor="transparent"
                color="whiteAlpha.900"
                minH={270}
            >
                <VStack w="100%" h="100%">
                    <VStack w="100%" pt={isMobile ? 20 : "100px"} pb={8} pr={isMobile ? 5 : 70} pl={isMobile ? 5 : 70}>
                        <Flex w="100%" direction="row" align="center" justify="space-between" wrap="wrap" gap={10}>
                            <HStack gap={5}>
                                <Link
                                    to="/"
                                    onClick={() => {
                                        window.scroll(0, 0);
                                    }}
                                >
                                    <Image src="/assets/logo_white.png" h={isMobile ? 10 : 50} />
                                </Link>
                                <Box>
                                    <Text fontSize={isMobile ? "sm" : "md"} fontWeight="bold">
                                        {intl.footerAnyQuestion[langKey]}
                                    </Text>
                                    <Text fontSize={isMobile ? "md" : "2xl"}>{ContactInfo.phone}</Text>
                                </Box>
                            </HStack>
                            <Flex wrap="wrap" direction="row" align="center" justify="center" gap={3}>
                                <Link to="/schedule">
                                    <Button size={isMobile ? "xs" : "sm"} variant="outline" colorScheme="whiteAlpha">
                                        {intl.navSchedule[langKey]}
                                    </Button>
                                </Link>
                                {/* <Button size={isMobile ? "xs" : "sm"} variant="outline" colorScheme="whiteAlpha">
                                    {intl.navRanking[langKey]}
                                </Button> */}
                                <Button
                                    size={isMobile ? "xs" : "sm"}
                                    variant="outline"
                                    colorScheme="whiteAlpha"
                                    onClick={onOpen}
                                >
                                    {intl.navAbout[langKey]}
                                </Button>
                                {/* <Button size={isMobile ? "xs" : "sm"} variant="outline" colorScheme="whiteAlpha">
                                    {intl.navContacts[langKey]}
                                </Button> */}
                                <Link to="/schedule">
                                    <Button
                                        size={isMobile ? "xs" : "sm"}
                                        rightIcon={<FiChevronRight />}
                                        bg="#8900F4"
                                        bgGradient="linear(to-br, #8900F4, #F400BF)"
                                        _hover={{ bg: "#8900F4" }}
                                        _active={{ bg: "#F400BF" }}
                                    >
                                        Play
                                    </Button>
                                </Link>
                            </Flex>
                        </Flex>
                        {/* Change justify to center when Become partner link will be introduced */}
                        <Flex w="100%" direction="row" align="center" justify="right">
                            {/* <Button size={isMobile ? "xs" : "sm"} variant="outline" colorScheme="quiz_pink_btn">
                                {intl.footerBecomePartner[langKey]}
                            </Button> */}
                            <HStack>
                                <Text fontWeight="bold">{intl.footerContacUs[langKey]}</Text>
                                <Center
                                    boxSize={8}
                                    bg="quiz_purple.500"
                                    borderRadius={8}
                                    onClick={() => window.open(ContactInfo.facebook)}
                                >
                                    <FiFacebook />
                                </Center>
                                <Center
                                    boxSize={8}
                                    bg="quiz_purple.500"
                                    borderRadius={8}
                                    onClick={() => window.open(ContactInfo.instagram)}
                                >
                                    <FiInstagram />
                                </Center>
                            </HStack>
                        </Flex>
                    </VStack>
                    <Center w="100%" borderTopWidth="1px" borderTopColor="whiteAlpha.600" pt={1} pb={1}>
                        <Flex direction="row" fontSize="xs" align="center" gap={3}>
                            <RiCopyrightLine />
                            <Text>2023 Quiz GO! All rights reserved </Text>
                        </Flex>
                    </Center>
                </VStack>
            </Box>
            <RulesModal lang={lang} onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
        </>
    );
};
