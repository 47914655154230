import React from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/inter/400.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/nanum-gothic";
import "@fontsource/ibm-plex-sans-kr";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./components/errorPage";
import HomePageContainer from "./components/homePage/homePage";
import ScheduleContainer from "./components/schedule/scheduleContainer";
import MainContainer from "./pages/mainContainer";
import { AdminContainer } from "./pages/adminContainer";
import { AdminEvent } from "./components/admin/adminEvents";
import { AdminBookings } from "./components/admin/adminBookings";
import { AdminCities } from "./components/admin/adminCities";
import { AdminPlaces } from "./components/admin/adminPlaces";
import { Login } from "./pages/login";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainContainer />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <HomePageContainer />,
            },
            {
                path: "schedule",
                element: <ScheduleContainer />,
            },
        ],
    },
    {
        path: "/admin",
        element: <AdminContainer />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "events",
                element: <AdminEvent />,
            },
            {
                path: "bookings",
                element: <AdminBookings />,
            },
            {
                path: "cities",
                element: <AdminCities />,
            },
            {
                path: "places",
                element: <AdminPlaces />,
            },
        ],
    },
    {
        path: "/login",
        element: <Login />,
        errorElement: <ErrorPage />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
