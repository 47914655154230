import { sign } from "crypto";
import { IResponse, IResponseData } from "../common/response";
import { Event, getEventFromDto } from "../event/event";
import { getWithAuth } from "../utils/baseFetch";
import { CreateOrUpdateEventDto } from "./dto/createOrUpdateEventDto";
import { EventDto } from "./dto/eventDto";

export class EventRepository {
    public async getEvents(
        cityId: string,
        placeId: string,
        dateFrom: Date,
        dateTo: Date,
        pageNumber: number,
        signal: AbortSignal
    ): Promise<IResponseData<Event[]>> {
        try {
            if (cityId === "" || placeId === "") return { ok: true, data: [], message: "" };

            const response = await getWithAuth<EventDto[]>(
                "/api/event/getEventsWithFilter",
                {
                    cityId: cityId,
                    placeId: placeId,
                    dateFrom: dateFrom.toISOString(),
                    dateTo: dateTo.toISOString(),
                    pageNumber: pageNumber.toString(),
                },
                signal
            );

            if (!response.ok) return { ok: false, message: response.message, data: [] };

            return { ok: true, data: response.data?.map((x) => getEventFromDto(x)), message: "" };
        } catch (ex) {
            if (ex instanceof Error && ex.name === "AbortError") {
                console.log(ex);
                return { ok: false, data: [], message: (ex as Error).message };
            } else {
                console.error(ex);
                return { ok: false, data: [], message: (ex as Error).message };
            }
        }
    }

    public async getUpcomingEvents(cityId: string, signal: AbortSignal): Promise<IResponseData<Event[]>> {
        try {
            if (cityId === undefined || cityId.length === 0) return { ok: false, message: "Incorrect cityId" };

            const response = await getWithAuth<EventDto[]>("/api/event/getUpcomingEvents", { cityId: cityId }, signal);

            if (!response.ok) return { ok: false, message: response.message };

            return { ok: true, data: response.data?.map((x) => getEventFromDto(x)) as Event[], message: "" };
        } catch (ex) {
            if (ex instanceof Error && ex.name === "AbortError") {
                console.log(ex);
                return { ok: false, message: (ex as Error).message };
            } else {
                console.error(ex);
                return { ok: false, message: (ex as Error).message };
            }
        }
    }

    public async getEventsDatesByPeriod(
        firstDay: Date,
        lastDay: Date,
        signal: AbortSignal
    ): Promise<IResponseData<Date[]>> {
        try {
            const response = await getWithAuth<string[]>(
                "/api/event/getEventsDatesByPeriod",
                { firstDay: firstDay.toISOString(), lastDay: lastDay.toISOString() },
                signal
            );

            if (!response.ok) return { ok: false, message: response.message, data: [] };

            return { data: response.data?.map((x) => new Date(x)), ok: true, message: "" };
        } catch (ex) {
            if (ex instanceof Error && ex.name === "AbortError") {
                return { ok: false, data: [], message: (ex as Error).message };
            } else {
                console.error(ex);
                return { ok: false, data: [], message: (ex as Error).message };
            }
        }
    }

    public async getAllEvents(showDeleted: boolean, signal?: AbortSignal): Promise<IResponseData<Event[]>> {
        try {
            const response = await getWithAuth<EventDto[]>(
                "/api/event/getAllEvents",
                { showDeleted: `${showDeleted}` },
                signal
            );

            if (!response.ok) return { ok: false, message: response.message };

            return { ok: true, data: response.data?.map((x) => getEventFromDto(x)) as Event[], message: "" };
        } catch (ex) {
            if (ex instanceof Error && ex.name === "AbortError") {
                console.log(ex);
                return { ok: false, message: (ex as Error).message };
            } else {
                console.error(ex);
                return { ok: false, message: (ex as Error).message };
            }
        }
    }

    public async getLastEventsByCityId(cityId?: string, signal?: AbortSignal): Promise<IResponseData<Event[]>> {
        try {
            if (!cityId) return { ok: false, message: "Invalid cityId" };

            const response = await getWithAuth<EventDto[]>(
                "/api/event/getLastEventsByCityId",
                { cityId: cityId },
                signal
            );

            if (!response.ok) return { ok: false, message: response.message };

            return { ok: true, data: response.data?.map((x) => getEventFromDto(x)) as Event[], message: "" };
        } catch (ex) {
            if (ex instanceof Error && ex.name === "AbortError") {
                console.log(ex);
                return { ok: false, message: (ex as Error).message };
            } else {
                console.error(ex);
                return { ok: false, message: (ex as Error).message };
            }
        }
    }
}

const instance = new EventRepository();
export { instance as eventRepository };
