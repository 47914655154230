import { Button, Menu, MenuButton, MenuItem, MenuList, Box, useBreakpoint } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { FiMapPin } from "react-icons/fi";
import { City } from "../../domain/city/city";
import { cityRepository } from "../../domain/city/cityRepository";
import { isMobileScreen } from "../../common/breakPoints";
import { CityContext } from "../../domain/city/cityContext";
import { LanguageContext } from "../../context/languageContext";
import { IntlKey, Languages } from "../../intl-languages";

export const CitySelector: React.FC = () => {
    const { city, cityArr, setCurrentCity, setCityArr } = useContext(CityContext);
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    const breakPoint = useBreakpoint();
    const isMobile = isMobileScreen(breakPoint);

    const handleCityChange = (city: City) => {
        setCurrentCity(city);
    };

    const icon = isMobile ? "" : <FiMapPin />;

    const loadCities = async () => {
        const citiesRes = await cityRepository.getAllCities(false);
        if (citiesRes.ok && citiesRes.data !== undefined) {
            setCurrentCity(citiesRes.data[0]);
            setCityArr(citiesRes.data);
        }
    };

    useEffect(() => {
        loadCities();
    }, []);

    return (
        <Box minW={66}>
            <Menu variant="languageSelector">
                <MenuButton
                    as={Button}
                    leftIcon={icon}
                    /* it was easier to style Button here, because in custom theme you can't place icon, 
and all stylings have to be done from scratch */
                    _hover={{ bg: "quiz_purple.50" }}
                    _focus={{ bg: "quiz_purple.200" }}
                    _active={{ bg: "quiz_purple.300" }}
                    color="quiz_purple.500"
                    borderWidth={1}
                    borderColor="quiz_purple.500"
                    bg="white.900"
                    size="sm"
                >
                    {city?.label[langKey]}
                </MenuButton>
                <MenuList>
                    {cityArr?.map((x) => {
                        return (
                            <MenuItem
                                key={x.id}
                                onClick={() => {
                                    handleCityChange(x);
                                }}
                            >
                                {x.label[langKey]}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </Menu>
        </Box>
    );
};
