import {
    Box,
    ChakraProvider,
    Flex,
    Text,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    VStack,
    MenuDivider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { auth } from "../common/authRedirect";
import { LanguageContextProvider } from "../context/languageContext";
import { Languages } from "../intl-languages";
import { customTheme } from "../theme/customTheme";

export const AdminContainer: React.FC = () => {
    const navigate = useNavigate();
    const [label, setLabel] = useState("");

    useEffect(() => {
        const controller = new AbortController();
        setLabel("");
        auth(navigate, controller.signal);

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <ChakraProvider theme={customTheme}>
            <LanguageContextProvider lang={Languages.en}>
                <VStack w="100%">
                    <Flex w="100%" h="70" bg="gray.200" direction="row" align="center" justify="center">
                        <Box pl={10}>
                            <Menu>
                                <MenuButton as={IconButton} icon={<FiMenu />}>
                                    Menu
                                </MenuButton>
                                <MenuList>
                                    <Link to="/admin/events">
                                        <MenuItem onClick={() => setLabel("Events")}>Events</MenuItem>
                                    </Link>
                                    <Link to="/admin/bookings">
                                        <MenuItem onClick={() => setLabel("Bookings")}>Bookings</MenuItem>
                                    </Link>
                                    <MenuDivider />
                                    <Link to="/admin/cities">
                                        <MenuItem onClick={() => setLabel("Cities")}>Cities</MenuItem>
                                    </Link>
                                    <Link to="/admin/places">
                                        <MenuItem onClick={() => setLabel("Places")}>Places</MenuItem>
                                    </Link>
                                </MenuList>
                            </Menu>
                        </Box>
                        <Spacer />
                        <Text>{label}</Text>
                        <Spacer />
                    </Flex>
                    <Box w="100%">
                        <Outlet />
                    </Box>
                </VStack>
            </LanguageContextProvider>
        </ChakraProvider>
    );
};
