import { Button, Menu, MenuButton, MenuItem, MenuList, Box, Text, useBreakpoint } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Languages } from "../../intl-languages";
import { TfiWorld } from "react-icons/tfi";
import { isMobileScreen } from "../../common/breakPoints";
import { LanguageContext } from "../../context/languageContext";

const LanguageSelector = () => {
    const { lang, setLanguage } = useContext(LanguageContext);

    const breakPoint = useBreakpoint();
    const isMobile = isMobileScreen(breakPoint);
    const icon = isMobile ? <></> : <TfiWorld />;

    return (
        <Box minW={66}>
            <Menu variant="languageSelector">
                <MenuButton
                    as={Button}
                    rightIcon={icon}
                    /*it was easier to style Button here, because in custom theme you can't place icon, 
and all stylings have to be done from scratch */
                    _hover={{ bg: "quiz_purple.50" }}
                    _focus={{ bg: "quiz_purple.200" }}
                    _active={{ bg: "quiz_purple.300" }}
                    color="quiz_purple.500"
                    borderWidth={1}
                    borderColor="quiz_purple.500"
                    bg="white.900"
                    size="sm"
                >
                    <Text casing="capitalize">{Languages[lang]}</Text>
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={() => setLanguage(Languages.한국어)}>{Languages[Languages.한국어]}</MenuItem>
                    <MenuItem onClick={() => setLanguage(Languages.en)}>
                        <Text casing="capitalize">{Languages[Languages.en]}</Text>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

export default LanguageSelector;
