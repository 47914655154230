import { extendTheme } from "@chakra-ui/react";
import { menuCustomTheme } from "./menuCustomTheme";

export const customTheme = extendTheme({
    colors: {
        quiz_yellow: {
            50: "#FFCB1333",
            100: "#FFFCAD",
            200: "#FFE352",
            300: "#FFCB13",
            400: "#FFBC13",
            500: "#FFB61D",
            600: "#B7791F",
            700: "#975A16",
            800: "#744210",
            900: "#5F370E",
        },
        quiz_blue: {
            100: "#e0e1f4",
            200: "#c2c2e9",
            300: "#a3a4de",
            400: "#8586d3",
            500: "#6668c9",
            600: "#4849be",
            700: "#3a3ba2",
            800: "#2f3084",
            900: "#242565",
        },
        quiz_green: {
            100: "#e4faf8",
            200: "#c9f4f0",
            300: "#afefe9",
            400: "#94e9e2",
            500: "#79e4da",
            600: "#5eded3",
            700: "#43d9cc",
            800: "#2ad2c3",
            900: "#25B7AA",
        },
        quiz_pink: {
            50: "#EC00C20C",
            100: "#EC00C219",
            200: "#EC00C233",
            300: "#FFD2F7",
            400: "#FFAEF1",
            500: "#FF66E4",
            600: "#FF33DB",
            70005: "#EC00C20C",
            70040: "#EC00C266",
            700: "#EC00C2",
            800: "#CA0DA8",
            900: "#86076F",
        },
        quiz_pink_btn: {
            50: "#EC00C20C",
            100: "#EC00C219",
            200: "#EC00C233",
            300: "#FFD2F7",
            400: "#FFAEF1",
            500: "#FF66E4",
            600: "#FF66E4",
            700: "#EC00C2",
            800: "#CA0DA8",
            900: "#86076F",
        },
        hero_play_bt: {
            100: "#fdebf3",
            200: "#fbd8e6",
            300: "#f9c4da",
            400: "#f7b1ce",
            500: "#EC4F90",
            600: "#f28ab5",
            700: "#f076a9",
            800: "#ee639c",
            900: "#EC4F90",
        },
        navbar_purple: {
            50: "#FAF5FF",
            100: "#E9D8FD",
            200: "#D6BCFA",
            300: "#B794F4",
            400: "#9F7AEA",
            500: "#7C48EF",
            600: "#6B46C1",
            700: "#582284",
            800: "#2F0E54",
            900: "#322659",
        },
        quiz_purple: {
            50: "#FAF5FF",
            100: "#E9D8FD",
            200: "#D6BCFA",
            300: "#B794F4",
            400: "#9F7AEA",
            50010: "#7C48EF19",
            500: "#7C48EF",
            600: "#6B46C1",
            700: "#582284",
            800: "#2F0E54",
            90060: "#32265999",
            900: "#322659",
        },
        quiz_red: {
            100: "#f8d3de",
            200: "#f5bdcd",
            300: "#f1a7bc",
            400: "#ee91ac",
            500: "#eb7a9b",
            600: "#e7648a",
            700: "#e44e79",
            800: "#e03869",
            900: "#dd2258",
        },
        quiz_red_btn: {
            100: "#f8d3de",
            200: "#f5bdcd",
            300: "#f1a7bc",
            400: "#ee91ac",
            500: "#dd2258",
            600: "#e7648a",
            700: "#e03869",
            800: "#e03869",
            900: "#dd2258",
        },
        quiz_teal: {
            100: "#B2F5EA",
            200: "#81E6D9",
            300: "#4FD1C5",
            400: "#22CBB7",
            500: "#1BBAA7",
            600: "#189E8E",
            700: "#0A584F",
            800: "#234E52",
            900: "#1D4044",
        },
        quiz_cyan: {
            50: "#EDFDFD",
            100: "#C4F1F9",
            200: "#9DECF9",
            300: "#76E4F7",
            40010: "#0BC5EA19",
            40020: "#0BC5EA33",
            40050: "#0BC5EA7F",
            400: "#0BC5EA",
            500: "#00B5D8",
            600: "#00A3C4",
            700: "#0987A0",
            800: "#086F83",
            900: "#065666",
        },
        quiz_cyan_btn: {
            50: "#9DECF9",
            100: "#C4F1F9",
            200: "#9DECF9",
            300: "#76E4F7",
            400: "#0BC5EA",
            500: "#00B5D8",
            600: "#00A3C4",
            700: "#0987A0",
            800: "#086F83",
            900: "#065666",
        },
    },
    components: {
        Menu: menuCustomTheme,
    },
    fonts: {
        heading: `'Montserrat', 'IBM Plex Sans KR','Nanum Gothic', sans-serif`,
        body: `'Inter', 'IBM Plex Sans KR', 'Nanum Gothic', sans-serif`,
    },
});
