import React, { useState } from "react";
import { City } from "./city";

interface ICityContextProps {
    city?: City;
    cityArr?: City[];
    setCurrentCity: (city: City) => void;
    setCityArr: (cityArr: City[]) => void;
}

export const CityContext = React.createContext<ICityContextProps>({
    city: undefined,
    setCurrentCity: () => {
        throw new Error("City context has not been initiated");
    },
    cityArr: undefined,
    setCityArr: () => {
        throw new Error("City context has not been initiated");
    },
});

interface ICityContextProviderProps {
    city?: City;
    cities?: City[];
    children?: React.ReactNode;
}

export const CityContextProvider: React.FC<ICityContextProviderProps> = ({ city, cities, children }) => {
    const [currCity, setCurrentCity] = useState(city);
    const [currCities, setCityArr] = useState(cities);

    return (
        <CityContext.Provider value={{ city: currCity, cityArr: currCities, setCurrentCity, setCityArr }}>
            {children}
        </CityContext.Provider>
    );
};
