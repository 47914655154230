import React from "react";
import { Box, ChakraProvider, SimpleGrid } from "@chakra-ui/react";
import Navbar from "../components/header/navbar";
import { customTheme } from "../theme/customTheme";
import { FooterContainer } from "../components/footer/footerContainer";
import { LanguageContextProvider } from "../context/languageContext";
import { Languages } from "../intl-languages";
import { CityContextProvider } from "../domain/city/cityContext";
import { City } from "../domain/city/city";
import { Outlet } from "react-router-dom";

const MainContainer: React.FC = () => {
    return (
        <ChakraProvider theme={customTheme}>
            <LanguageContextProvider lang={Languages.한국어}>
                <CityContextProvider cities={[] as City[]} city={{ id: "", label: { en: "", 한국어: "" } } as City}>
                    <Box fontWeight="semibold" wordBreak="keep-all">
                        <Navbar />
                        <Outlet />
                        <FooterContainer />
                    </Box>
                </CityContextProvider>
            </LanguageContextProvider>
        </ChakraProvider>
    );
};

export default MainContainer;
