import { PlaceDto } from "./dto/placeDto";

export type Place = {
    // unique id
    id: string;
    // city id
    cityId: string;
    // place name
    name: string;
    // place address
    address: string;
    // map service url
    geoTagUrl: string;

    isDeleted: boolean;
};

export const getPlaceFromDto = (dto: PlaceDto): Place => {
    return {
        id: dto.id ?? "",
        cityId: dto.cityId ?? "",
        name: dto.name ?? "",
        address: dto.address ?? "",
        geoTagUrl: dto.geoTagUrl ?? "",
        isDeleted: dto.isDeleted ?? false,
    };
};
