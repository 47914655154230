import { CreateUpdateBookingDto } from "../booking/dto/createUpdateBooking";
import { CityDto } from "../city/dto/cityDto";
import { IResponse } from "../common/response";
import { CreateOrUpdateEventDto } from "../event/dto/createOrUpdateEventDto";
import { PlaceDto } from "../place/dto/placeDto";
import { getWithAuth, postWithAuth } from "../utils/baseFetch";

export class AdminRepository {
    public async createOrUpdateBooking(dto: CreateUpdateBookingDto, signal?: AbortSignal): Promise<IResponse> {
        try {
            const response = await postWithAuth("/admin/createUpdateBooking", JSON.stringify(dto), signal);

            if (!response.ok)
                return {
                    ok: false,
                    message: `Error occured on booking/create: ${response.message}`,
                };

            return { ok: true, message: "" };
        } catch (ex) {
            console.error(ex);
            return { ok: false, message: (ex as Error).message };
        }
    }

    public async createOrUpdateCity(dto: CityDto, signal?: AbortSignal): Promise<IResponse> {
        try {
            const response = await postWithAuth("/admin/createOrUpdateCity", JSON.stringify(dto), signal);

            if (!response.ok)
                return { ok: false, message: `Error occured on admin/createOrUpdateCity: ${response.message}` };

            return { ok: true, message: "" };
        } catch (ex) {
            console.log(ex);
            return { ok: false, message: (ex as Error).message };
        }
    }

    public async createOrUpdateEvent(dto: CreateOrUpdateEventDto, signal?: AbortSignal): Promise<IResponse> {
        try {
            const response = await postWithAuth("/admin/createOrUpdateEvent", JSON.stringify(dto), signal);

            if (!response.ok)
                return { ok: false, message: `Error occured on admin/createOrUpdateEvent: ${response.message}` };

            return { ok: true, message: "" };
        } catch (ex) {
            if (ex instanceof Error && ex.name === "AbortError") {
                console.log(ex);
                return { ok: false, message: (ex as Error).message };
            } else {
                console.error(ex);
                return { ok: false, message: (ex as Error).message };
            }
        }
    }

    public async createOrUpdatePlace(placeDto: PlaceDto, signal?: AbortSignal): Promise<IResponse> {
        try {
            const response = await postWithAuth("/admin/createOrUpdatePlace", JSON.stringify(placeDto), signal);

            if (!response.ok) return { ok: false, message: response.message };

            return { ok: true, message: "" };
        } catch (ex) {
            if (ex instanceof Error && ex.name === "AbortError") {
                console.log(ex);
                return { ok: false, message: (ex as Error).message };
            } else {
                console.error(ex);
                return { ok: false, message: (ex as Error).message };
            }
        }
    }
}

const instance = new AdminRepository();
export { instance as adminRepository };
