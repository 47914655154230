import React from "react";
import { useBreakpoint } from "@chakra-ui/react";
import { isMobileScreen } from "../../common/breakPoints";
import { HeroDesktop } from "./heroDesktop";
import { HeroMobile } from "./heroMobile";

const Hero: React.FC = () => {
    const breakPoint = useBreakpoint();

    return isMobileScreen(breakPoint) ? <HeroMobile /> : <HeroDesktop />;
};

export default Hero;
