import {
    Button,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Select,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FiEdit, FiMoreVertical, FiPlus, FiRefreshCw, FiTrash2 } from "react-icons/fi";
import { adminRepository } from "../../domain/admin/adminRepository";
import { City } from "../../domain/city/city";
import { cityRepository } from "../../domain/city/cityRepository";
import { Place } from "../../domain/place/place";
import { placeRepository } from "../../domain/place/placeRepository";
import { AdminAuthWrapper } from "./adminAuthWrapper";
import { EditPlaceModal } from "./editPlaceModal";

export const AdminPlaces: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [showDeleted, setShowDeleted] = useState(false);
    const [city, setCity] = useState<City>();
    const [cities, setCities] = useState<City[]>();
    const [places, setPlaces] = useState<Place[]>();
    const [placeOnEdit, setPlaceOnEdit] = useState<Place>();

    const handleShowDeleted = (e: SyntheticEvent) => {
        setShowDeleted((e.target as HTMLInputElement).checked);
    };

    const handleCityChange = (e: SyntheticEvent) => {
        const cityId = (e.target as HTMLInputElement).value;
        setCity(cities?.find((x) => x.id === cityId));
    };

    const handlePlaceEdit = (place?: Place) => {
        setPlaceOnEdit(place);
        onOpen();
    };

    const handleDeletePlace = async (place?: Place) => {
        const response = await adminRepository.createOrUpdatePlace({
            id: place?.id,
            cityId: place?.cityId,
            name: place?.name,
            address: place?.address,
            geoTagUrl: place?.geoTagUrl,
            isDeleted: true,
        });

        if (!response.ok) {
            toast({
                title: "Error",
                description: "Something went wrong. Please reload page and try again!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Success",
            description: "Place has been succesfully deleted!",
            status: "success",
            duration: 9000,
            isClosable: true,
        });

        loadPlaces();
    };

    const handleRestorePlace = async (place?: Place) => {
        const response = await adminRepository.createOrUpdatePlace({
            id: place?.id,
            cityId: place?.cityId,
            name: place?.name,
            address: place?.address,
            geoTagUrl: place?.geoTagUrl,
            isDeleted: false,
        });

        if (!response.ok) {
            toast({
                title: "Error",
                description: "Something went wrong. Please reload page and try again!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Success",
            description: "Place has been succesfully restored!",
            status: "success",
            duration: 9000,
            isClosable: true,
        });

        loadPlaces();
    };

    const loadCities = async (signal?: AbortSignal) => {
        const response = await cityRepository.getAllCities(false, signal);
        if (response.ok) setCities(response.data);
    };

    const loadPlaces = async (signal?: AbortSignal) => {
        const response = await placeRepository.getPlacesByCity(city?.id ?? "", showDeleted, signal);
        if (response.ok) setPlaces(response.data);
        else setPlaces([]);
    };

    useEffect(() => {
        const controller = new AbortController();
        loadPlaces(controller.signal);

        return () => {
            controller.abort();
        };
    }, [city, showDeleted]);

    useEffect(() => {
        const controller = new AbortController();
        loadCities(controller.signal);

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <>
            <AdminAuthWrapper>
                <VStack>
                    <Flex pl={10} pt={2} w="100%" gap={10} align="center" direction="row" justify="left">
                        <Button leftIcon={<FiPlus />} onClick={() => handlePlaceEdit()}>
                            Add place
                        </Button>
                        <Flex align="center" direction="row" justify="space-between" gap={2}>
                            <Text fontSize="sm" whiteSpace="normal">
                                Show deleted
                            </Text>
                            <Switch isChecked={showDeleted} onChange={handleShowDeleted} size="md" colorScheme="red" />
                        </Flex>
                        <Select value={city?.id} onChange={handleCityChange} maxW={700}>
                            <option value=""></option>
                            {cities?.map((x) => (
                                <option key={x.id} value={x.id}>{`${x.label.en}`}</option>
                            ))}
                        </Select>
                    </Flex>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Address</Th>
                                    <Th>Geo tag URL</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {places?.map((x) => {
                                    return (
                                        <Tr key={x.id} bg={x.isDeleted ? "red.200" : ""}>
                                            <Td>{x.name}</Td>
                                            <Td>{x.address}</Td>
                                            <Td>{x.geoTagUrl}</Td>
                                            <Td>
                                                <Menu>
                                                    <MenuButton as={IconButton} icon={<FiMoreVertical />} />
                                                    <MenuList>
                                                        <MenuItem icon={<FiEdit />} onClick={() => handlePlaceEdit(x)}>
                                                            Edit
                                                        </MenuItem>
                                                        <MenuDivider />
                                                        {x.isDeleted ? (
                                                            <MenuItem
                                                                icon={<FiRefreshCw />}
                                                                color="green.500"
                                                                onClick={() => handleRestorePlace(x)}
                                                            >
                                                                Restore
                                                            </MenuItem>
                                                        ) : (
                                                            <MenuItem
                                                                icon={<FiTrash2 />}
                                                                color="red.500"
                                                                onClick={() => handleDeletePlace(x)}
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        )}
                                                    </MenuList>
                                                </Menu>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </VStack>
                <EditPlaceModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    place={placeOnEdit}
                    city={city}
                    loadPlaces={loadPlaces}
                />
            </AdminAuthWrapper>
        </>
    );
};
