import { IResponse } from "../common/response";
import { postWithAuth } from "../utils/baseFetch";
import { authToken } from "./authToken";

export class AuthRepository {
    public async SignIn(login?: string, password?: string): Promise<IResponse> {
        try {
            console.log(process.env.REACT_APP_PORT);
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/admin/auth", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                },
                body: JSON.stringify({
                    login: login,
                    password: password,
                }),
            });

            if (!response.ok)
                return {
                    ok: false,
                    message: `${response.statusText}`,
                };

            const token = await response.json();
            authToken.setToken(token);

            return { ok: true, message: "" };
        } catch (ex) {
            console.error(ex);
            return { ok: false, message: (ex as Error).message };
        }
    }

    public async CheckAuth(signal?: AbortSignal): Promise<IResponse> {
        try {
            return await postWithAuth<string>("/admin/auth/validate", "", signal);
        } catch (ex) {
            console.error(ex);
            return { ok: false, message: (ex as Error).message };
        }
    }
}

const instance = new AuthRepository();
export { instance as authRepository };
