import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    VStack,
    Grid,
    GridItem,
    Flex,
    Text,
    Divider,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    InputGroup,
    InputLeftAddon,
    Slider,
    SliderFilledTrack,
    SliderTrack,
    SliderThumb,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Spacer,
    Textarea,
    HStack,
    Checkbox,
    Alert,
    Box,
    Spinner,
    useBreakpoint,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { FiClock, FiMapPin } from "react-icons/fi";
import { isMobileScreen } from "../../common/breakPoints";
import { LanguageContext } from "../../context/languageContext";
import { bookingRepository } from "../../domain/booking/bookingRepository";
import { BookingType } from "../../domain/booking/bookingType";
import { Event } from "../../domain/event/event";
import { intl, IntlKey, Languages, LanguageCode } from "../../intl-languages";
import { ContactInfo } from "../../common/contactInfo";

interface IProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    event: Event;
    dateFormated: {
        month: string;
        weekday: string;
        dayDate: string;
        hours: string;
        minutes: string;
    };
}

export const BookEventModal: React.FC<IProps> = ({ isOpen, onOpen, onClose, event, dateFormated }) => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const langCode = LanguageCode.getCode(lang);

    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);

    // all en and kr characters and digits with length under 60 symbols
    const nameRegEx = /[\w\sA-Za-z0-9\u3131-\u3163\uac00-\ud7a3\u1100-\u11ff]{1,60}$/i;
    // 12-digit format phone number
    const phoneRegEx = /^[\d]{12}$/i;
    // standard email validation
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

    const [teamName, setTeamName] = useState("");
    const [isNotValidTeamName, setIsNotValidTeamName] = useState(false);
    const [teamNameNotAvailable, setTeamNameNotAvailable] = useState(false);

    const [teamLeaderName, setTeamLeaderName] = useState("");
    const [isNotValidTeamLeaderName, setIsNotValidTeamLeaderName] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [isNotValidPhoneNumber, setIsNotValidPhoneNumber] = useState(false);

    const [email, setEmail] = useState("");
    const [isNotValidEmail, setIsNotValidEmail] = useState(false);

    const [teammatesCount, setTeammatesCount] = useState(2);
    const [comment, setComment] = useState("");

    const [hasCertificate, setHasCertificate] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(false);

    const [isDisabledForm, setIsDisabledForm] = useState(false);
    const [isBookingStarted, setIsBookingStarted] = useState(false);

    const [showFailedAlert, setShowFailedAlert] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const validateName = (name: string) => {
        return nameRegEx.test(name);
    };

    const handleTeamNameChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setIsNotValidTeamName(!validateName(val));
        setTeamName(val);
        setTeamNameNotAvailable(false);
    };

    const handleTeamLeaderNameChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setIsNotValidTeamLeaderName(!validateName(val));
        setTeamLeaderName(val);
    };

    const validatePhoneNumber = (phone: string) => {
        return phoneRegEx.test(phone);
    };

    const handlePhoneNumberChange = (e: SyntheticEvent) => {
        e.preventDefault();
        const val = (e.target as HTMLInputElement).value;
        setIsNotValidPhoneNumber(!validatePhoneNumber(val));
        setPhoneNumber(val);
    };

    const validateEmail = (email: string) => {
        return emailRegEx.test(email);
    };

    const handleEmailChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setIsNotValidEmail(!validateEmail(val));
        setEmail(val);
    };

    const handleTeammatesCountChange = (count: number) => {
        setTeammatesCount(count);
    };

    const handleCommentChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setComment(val);
    };

    const toggleHasCertificate = (e: SyntheticEvent) => {
        setHasCertificate((e.target as HTMLInputElement).checked);
    };

    const toggleIsFirstTime = (e: SyntheticEvent) => {
        setIsFirstTime((e.target as HTMLInputElement).checked);
    };

    const setInitialState = () => {
        setTeamName("");
        setTeamLeaderName("");
        setPhoneNumber("");
        setEmail("");
        setTeammatesCount(0);
        setComment("");
        setIsFirstTime(false);
        setHasCertificate(false);
        setIsBookingStarted(false);
        setShowFailedAlert(false);
        setShowSuccessAlert(false);
        setIsDisabledForm(false);
    };

    const onModalClose = () => {
        setInitialState();
        onClose();
    };

    const isBookBtnDisabled = () => {
        return (
            isNotValidEmail ||
            isNotValidPhoneNumber ||
            isNotValidTeamLeaderName ||
            isNotValidTeamName ||
            teamNameNotAvailable ||
            isDisabledForm
        );
    };

    const isFormValid = async () => {
        const teamNameIsValid = validateName(teamName);
        const leaderNameIsValid = validateName(teamLeaderName);
        const phoneIsValid = validatePhoneNumber(phoneNumber);
        const emailIsValid = validateEmail(email);

        setIsNotValidTeamName(!teamNameIsValid);
        setIsNotValidTeamLeaderName(!leaderNameIsValid);
        setIsNotValidPhoneNumber(!phoneIsValid);
        setIsNotValidEmail(!emailIsValid);

        if (!teamNameIsValid || !leaderNameIsValid || !phoneIsValid || !emailIsValid) return true;

        const otherTeams = await bookingRepository.getTeamNamesFromBookings(event.id);
        if (
            otherTeams.ok &&
            otherTeams.data !== undefined &&
            otherTeams.data.some((x) => x.toLocaleLowerCase(langCode) === teamName.toLocaleLowerCase(langCode))
        ) {
            setTeamNameNotAvailable(true);
            return false;
        }

        return true;
    };

    const bookEvent = async () => {
        setIsDisabledForm(true);
        setIsBookingStarted(true);

        if (!(await isFormValid())) {
            setIsBookingStarted(false);
            setIsDisabledForm(false);
            return;
        }

        const bookingType = event.placesLeft > 0 ? BookingType.Regular : BookingType.Reserve;

        const bookingRes = await bookingRepository.createOrUpdate({
            eventId: event.id,
            eventVersion: event.version,
            teamName: teamName,
            teamLeaderName: teamLeaderName,
            phoneNumber: phoneNumber,
            email: email,
            teammatesCount: teammatesCount,
            comment: comment,
            hasBonusCertificate: hasCertificate,
            isFirstGame: isFirstTime,
            dateOfBooking: new Date(Date.now()).toISOString(),
            bookingType: bookingType,
        });

        if (!bookingRes.ok) {
            setIsBookingStarted(false);
            setShowFailedAlert(true);
            return;
        }

        setShowSuccessAlert(true);
        setIsBookingStarted(false);

        window.gtag("config", "AW-11177268144");
        window.gtag("event", "conversion", {
            send_to: "AW-11177268144/t5irCOLWzqAYELCn3tEp",
        });

        ym(93589929, "reachGoal", "bool");
        return;
    };

    return (
        <Modal isOpen={isOpen} onClose={onModalClose} size="lg">
            <ModalOverlay h="100%" w="100%" bg="quiz_purple.90060" />
            <ModalContent bgColor="white">
                <ModalCloseButton />
                <ModalBody pb={5} pt={isMobile ? 10 : 10} pl={isMobile ? 5 : 10} pr={isMobile ? 5 : 10}>
                    <VStack gap={2}>
                        <Grid templateColumns="repeat(3, 1fr)" templateRows="repeat(2, 1fr)" gap={3} w="100%">
                            <GridItem colSpan={2} rowSpan={1} borderRadius={16} borderWidth={1} p={3}>
                                <VStack gap={1} alignItems="left">
                                    <Text fontWeight="bold" fontSize="xl">
                                        {event.name}
                                    </Text>
                                    <Text wordBreak="keep-all" fontSize="xs">
                                        {event.description}
                                    </Text>
                                </VStack>
                            </GridItem>
                            <GridItem colSpan={1} rowSpan={2}>
                                <VStack h="100%">
                                    <Flex
                                        w="100%"
                                        justify="center"
                                        align="center"
                                        gap={1}
                                        pt={1}
                                        pb={1}
                                        borderRadius={6}
                                        borderWidth={1}
                                        borderColor="quiz_purple.500"
                                        bg="quiz_purple.50010"
                                        color="quiz_purple.500"
                                        fontWeight="bold"
                                    >
                                        <FiClock />
                                        <Box>
                                            {dateFormated.hours}:{dateFormated.minutes}
                                        </Box>
                                    </Flex>
                                    <Flex
                                        w="100%"
                                        h="100%"
                                        direction="column"
                                        justify="center"
                                        align="center"
                                        p={5}
                                        borderRadius={8}
                                        borderWidth={1}
                                        borderColor="quiz_pink.600"
                                        bg="quiz_pink.70005"
                                    >
                                        <Text fontSize="0.8em" textAlign="center">
                                            {dateFormated.month}
                                        </Text>
                                        <Text fontSize="4em" as="b" textAlign="center">
                                            {dateFormated.dayDate}
                                        </Text>
                                        <Text fontSize="1em" textAlign="center">
                                            {dateFormated.weekday}
                                        </Text>
                                    </Flex>
                                </VStack>
                            </GridItem>
                            <GridItem colSpan={2} rowSpan={1}>
                                <Flex h="100%" direction="column" justify="end" gap={3}>
                                    <VStack alignItems="left">
                                        <Flex justify="left" align="center" gap={1} color="quiz_yellow.400">
                                            <FiMapPin />
                                            <Text as="b">{event.place.name}</Text>
                                        </Flex>
                                        <Text fontSize="0.9em">{event.place.address}</Text>
                                    </VStack>
                                    <Button
                                        bg="quiz_cyan.40010"
                                        borderWidth={1}
                                        borderColor="quiz_cyan.400"
                                        color="quiz_cyan.400"
                                        size="sm"
                                        _hover={{ bg: "quiz_cyan.40020" }}
                                        _active={{ bg: "quiz_cyan.40050" }}
                                        onClick={() => {
                                            window.open(event.place.geoTagUrl);
                                        }}
                                    >
                                        {intl.eventCardWhere[langKey]}
                                    </Button>
                                </Flex>
                            </GridItem>
                        </Grid>
                        <Divider />
                        {event.placesLeft === 0 && (
                            <Alert status="warning">{intl.bookModalAlertFallbackBooking[langKey]}</Alert>
                        )}
                        <FormControl isRequired isInvalid={isNotValidTeamName || teamNameNotAvailable}>
                            <FormLabel>{intl.bookModalTeamName[langKey]}</FormLabel>
                            <Input
                                value={teamName}
                                onChange={handleTeamNameChange}
                                placeholder={intl.bookModalEnterTeamName[langKey]}
                                disabled={isDisabledForm}
                            />
                            {isNotValidTeamName && (
                                <FormErrorMessage>{intl.bookModalNameNotValid[langKey]}</FormErrorMessage>
                            )}
                            {teamNameNotAvailable && (
                                <FormErrorMessage>{intl.bookModalTeamNameNotAvailable[langKey]}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl isRequired isInvalid={isNotValidTeamLeaderName}>
                            <FormLabel>{intl.bookModalTeamleaderName[langKey]}</FormLabel>
                            <Input
                                value={teamLeaderName}
                                onChange={handleTeamLeaderNameChange}
                                placeholder={intl.bookModalEnterTeamleaderName[langKey]}
                                disabled={isDisabledForm}
                            />
                            {isNotValidTeamLeaderName && (
                                <FormErrorMessage>{intl.bookModalNameNotValid[langKey]}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl isRequired isInvalid={isNotValidPhoneNumber}>
                            <FormLabel>{intl.bookModalPhoneNumber[langKey]}</FormLabel>
                            <InputGroup>
                                <InputLeftAddon color="quiz_purple.500" bg="quiz_purple.50" children="+" />
                                <Input
                                    value={phoneNumber}
                                    type="number"
                                    placeholder={intl.bookModalEnterPhoneNumber[langKey]}
                                    onChange={handlePhoneNumberChange}
                                    disabled={isDisabledForm}
                                />
                            </InputGroup>
                            {isNotValidPhoneNumber && (
                                <FormErrorMessage>{intl.bookModalPhoneNotValid[langKey]}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl isRequired isInvalid={isNotValidEmail}>
                            <FormLabel>{intl.bookModalEmail[langKey]}</FormLabel>
                            <Input
                                value={email}
                                onChange={handleEmailChange}
                                placeholder={intl.bookModalEnterEmail[langKey]}
                                disabled={isDisabledForm}
                            />
                            {isNotValidEmail && (
                                <FormErrorMessage>{intl.bookModalEmailNotValid[langKey]}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormLabel>{intl.bookModalTeammatesCount[langKey]}</FormLabel>
                            <Flex w="100%" justify="space-between" gap={5}>
                                <Slider
                                    min={2}
                                    max={event.maxTeammatesCount}
                                    step={1}
                                    defaultValue={5}
                                    flex={1}
                                    colorScheme="quiz_purple"
                                    focusThumbOnChange={false}
                                    value={teammatesCount}
                                    onChange={handleTeammatesCountChange}
                                    isDisabled={isDisabledForm}
                                >
                                    <SliderTrack>
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb boxSize={4} />
                                </Slider>
                                <NumberInput
                                    min={2}
                                    max={event.maxTeammatesCount}
                                    maxW="100px"
                                    value={teammatesCount}
                                    onChange={(v) => handleTeammatesCountChange(Number(v))}
                                    isDisabled={isDisabledForm}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <FormLabel optionalIndicator={true}>{intl.bookModalComment[langKey]}</FormLabel>
                            <Textarea
                                disabled={isDisabledForm}
                                resize="none"
                                placeholder={intl.bookModalLeaveAComment[langKey]}
                                onChange={handleCommentChange}
                            />
                        </FormControl>
                        <Flex w="100%" justify="space-between" direction="row" borderWidth={1} borderRadius={6} p={3}>
                            <Checkbox
                                checked={isFirstTime}
                                onChange={toggleHasCertificate}
                                disabled={isDisabledForm}
                                colorScheme="quiz_purple"
                            >
                                {intl.bookModalFirstTime[langKey]}
                            </Checkbox>
                            {/* <Checkbox
                                checked={hasCertificate}
                                onChange={toggleIsFirstTime}
                                disabled={isDisabledForm}
                                colorScheme="quiz_purple"
                            >
                                {intl.bookModalHasCertificate[langKey]}
                            </Checkbox> */}
                        </Flex>
                        <VStack w="100%">
                            {showFailedAlert && (
                                <Alert status="error">
                                    {intl.bookModalAlertBookFail[langKey]}
                                    {ContactInfo.phone}
                                </Alert>
                            )}
                            {showSuccessAlert && (
                                <Alert status="success">
                                    {intl.bookModalAlertBookSuccess[langKey]}
                                    {ContactInfo.phone}
                                </Alert>
                            )}
                            <HStack w="100%">
                                <Box
                                    p={3}
                                    borderRadius={8}
                                    color="whiteAlpha.900"
                                    bg="quiz_purple.500"
                                    fontSize={isMobile ? "xs" : "sm"}
                                >
                                    <Text as="b">
                                        {intl.bookModalOverallPrice[langKey]}: {teammatesCount} x {event.price} ₩ ={" "}
                                        {teammatesCount * event.price} ₩
                                    </Text>
                                </Box>
                                <Spacer />
                                <Button
                                    minW={70}
                                    disabled={isBookBtnDisabled()}
                                    onClick={bookEvent}
                                    colorScheme="quiz_red_btn"
                                >
                                    {isBookingStarted ? <Spinner /> : intl.eventCardBook[langKey]}
                                </Button>
                            </HStack>
                        </VStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
