import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { adminRepository } from "../../domain/admin/adminRepository";
import { City } from "../../domain/city/city";
import { cityRepository } from "../../domain/city/cityRepository";

interface IProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    city?: City;
    loadCities: () => {};
}

interface IState {
    id?: string;
    enName?: string;
    krName?: string;
    isDeleted?: boolean;
}

export const EditCityModal: React.FC<IProps> = ({ isOpen, onOpen, onClose, city, loadCities }) => {
    const [state, setState] = useState<IState>();

    const [showFailureMsg, setShowFailureMsg] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    const handleModalCLose = () => {
        setState({
            id: "",
            enName: "",
            krName: "",
            isDeleted: false,
        });
        setShowFailureMsg(false);
        setShowSuccessMsg(false);
        setIsFormDisabled(false);
        onClose();
    };

    const handleEnNameChange = (e: SyntheticEvent) => {
        setState({ ...state, enName: (e.target as HTMLInputElement).value });
    };

    const handleKrNameChange = (e: SyntheticEvent) => {
        setState({ ...state, krName: (e.target as HTMLInputElement).value });
    };

    const handleSave = async () => {
        setIsFormDisabled(true);
        const response = await adminRepository.createOrUpdateCity({
            id: state?.id,
            enName: state?.enName,
            krName: state?.krName,
            isDeleted: state?.isDeleted,
        });

        if (!response.ok) {
            setShowFailureMsg(true);
            setShowSuccessMsg(false);
            setIsFormDisabled(false);
            return;
        }

        setShowFailureMsg(false);
        setShowSuccessMsg(true);
        loadCities();
    };

    useEffect(() => {
        setState({
            id: city?.id,
            krName: city?.label.한국어,
            enName: city?.label.en,
            isDeleted: city?.isDeleted,
        });
    }, [city]);

    return (
        <Modal isOpen={isOpen} onClose={handleModalCLose} size="xl">
            <ModalOverlay h="100%" w="100%" />
            <ModalContent bgColor="white">
                <ModalCloseButton />
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <VStack gap={5}>
                        <FormControl>
                            <FormLabel>Korean name</FormLabel>
                            <Input value={state?.krName} onChange={handleKrNameChange} isDisabled={isFormDisabled} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>English name</FormLabel>
                            <Input value={state?.enName} onChange={handleEnNameChange} isDisabled={isFormDisabled} />
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Box w="100%" pr="18">
                        {showSuccessMsg && (
                            <Alert status="success">
                                {city === undefined ? "New city was successfully created" : "Update was successfull"}
                            </Alert>
                        )}
                        {showFailureMsg && <Alert status="error">Oops! Error occured! Please, try again!</Alert>}
                    </Box>
                    <Button isDisabled={isFormDisabled} onClick={handleSave}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
