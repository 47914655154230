import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Textarea,
    VStack,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { adminRepository } from "../../domain/admin/adminRepository";
import { City } from "../../domain/city/city";
import { Event } from "../../domain/event/event";
import { eventRepository } from "../../domain/event/eventRepository";
import { Place } from "../../domain/place/place";
import { placeRepository } from "../../domain/place/placeRepository";

interface IProps {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    event?: Event;
    cities?: City[];
    reloadEvents: () => void;
}

interface IState {
    id?: string | undefined;
    name?: string | undefined;
    city?: City | undefined;
    place?: Place | undefined;
    dateTime?: Date | undefined;
    bookingStartDate?: Date | undefined;
    desc?: string | undefined;
    price?: number | undefined;
    maxTeammatesCount?: number | undefined;
    maxTeamCount?: number | undefined;
    maxReserveCount?: number | undefined;
    photoGalleryUrl?: string | undefined;
    photoCoverUrl?: string | undefined;
    isDeleted?: boolean;
    version?: string | undefined;
}

export const EditEventModal: React.FC<IProps> = ({ isOpen, onOpen, onClose, event, cities, reloadEvents }) => {
    const [state, setState] = useState<IState>();

    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [showFailedMsg, setShowFailedMsg] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    const [places, setPlaces] = useState<Place[]>();

    const handleNameChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setState({ ...state, name: val });
    };

    const handleCityChange = (e: SyntheticEvent) => {
        const cityId = (e.target as HTMLSelectElement).value;
        const city = cities?.find((x) => x.id === cityId);
        setState({ ...state, city: city });
    };

    const handlePlaceChange = (e: SyntheticEvent) => {
        const placeId = (e.target as HTMLSelectElement).value;
        const place = places?.find((x) => x.id === placeId);
        setState({ ...state, place: place });
    };

    const handleDateTimeChange = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;
        const newDate = new Date(value);
        const today = new Date();
        if (newDate.getTime() >= today.getTime()) setState({ ...state, dateTime: newDate });
    };

    const handleBookingStartDateChange = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setState({ ...state, bookingStartDate: new Date(value) });
    };

    const handleDescriptionChange = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setState({ ...state, desc: value });
    };

    const handlePriceChange = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setState({ ...state, price: Math.abs(Number(value)) });
    };

    const handleMaxTeamCountChange = (value: string) => {
        setState({ ...state, maxTeamCount: Math.abs(Number(value)) });
    };

    const handleMaxReserveCountChange = (value: string) => {
        setState({ ...state, maxReserveCount: Math.abs(Number(value)) });
    };

    const handleMaxTeammatesCountChange = (value: string) => {
        setState({ ...state, maxTeammatesCount: Math.abs(Number(value)) });
    };

    const handlePhotoUrlChange = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setState({ ...state, photoGalleryUrl: value });
    };

    const handlePhotoCoverUrlChange = (e: SyntheticEvent) => {
        const value = (e.target as HTMLInputElement).value;
        setState({ ...state, photoCoverUrl: value });
    };

    const loadPlaces = async (signal?: AbortSignal) => {
        if (state?.city === undefined) return;

        const response = await placeRepository.getPlacesByCity(state?.city?.id, false, signal);
        if (response.ok && response.data !== undefined) setPlaces(response.data);
    };

    useEffect(() => {
        const controller = new AbortController();
        loadPlaces(controller.signal);

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        loadPlaces(controller.signal);

        return () => {
            controller.abort();
        };
    }, [state?.city]);

    useEffect(() => {
        const controller = new AbortController();
        const place = places?.find((p) => p.id === event?.place.id);
        setState({ ...state, place: place });

        return () => {
            controller.abort();
        };
    }, [places]);

    const setInitialState = () => {
        const city = cities?.find((c) => c.id === event?.city.id);
        setState({
            ...state,
            id: event?.id,
            name: event?.name,
            city: city,
            dateTime: event?.date,
            bookingStartDate: event?.bookingStartDate,
            price: event?.price,
            desc: event?.description,
            maxReserveCount: event?.maxFallbackPlacesCount,
            maxTeamCount: event?.maxBookingCount,
            maxTeammatesCount: event?.maxTeammatesCount,
            photoGalleryUrl: event?.photoGalleryUrl,
            photoCoverUrl: event?.photoCoverUrl,
            isDeleted: event?.isDeleted,
            version: event?.version,
        });

        setShowFailedMsg(false);
        setShowSuccessMsg(false);
        setIsSaveDisabled(false);

        loadPlaces();
    };

    useEffect(() => {
        setInitialState();
    }, [event]);

    // returns local datetime string in yyyy-MM-ddThh:mm
    const getRFC3339DateString = (date?: Date) => {
        if (date === undefined) return "";

        const dateTimeLocal = new Date(date);
        dateTimeLocal.setMinutes(dateTimeLocal.getMinutes() - dateTimeLocal.getTimezoneOffset());
        const str = dateTimeLocal.toISOString().slice(0, 16);
        return str;
    };

    const onModalClose = () => {
        setInitialState();
        onClose();
    };

    const createOrUpdateEvent = async () => {
        // TODO: block save btn till update in progress
        const response = await adminRepository.createOrUpdateEvent({
            id: state?.id,
            name: state?.name,
            cityId: state?.city?.id,
            placeId: state?.place?.id,
            date: state?.dateTime?.toISOString(),
            bookingStartDate: state?.bookingStartDate?.toISOString(),
            description: state?.desc,
            price: state?.price,
            maxBookingCount: state?.maxTeamCount,
            maxFallbackPlacesCount: state?.maxReserveCount,
            maxTeammatesCount: state?.maxTeammatesCount,
            photoGalleryUrl: state?.photoGalleryUrl,
            photoCoverUrl: state?.photoCoverUrl,
            isDeleted: state?.isDeleted,
            version: event?.version,
        });

        if (!response.ok) {
            setShowSuccessMsg(false);
            setShowFailedMsg(true);
            return;
        }

        setShowFailedMsg(false);
        setShowSuccessMsg(true);
        if (event === undefined) setIsSaveDisabled(true);
        reloadEvents();
    };

    return (
        <Modal isOpen={isOpen} onClose={onModalClose} size="xl">
            <ModalOverlay h="100%" w="100%" />
            <ModalContent bgColor="white">
                <ModalCloseButton />
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <VStack>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input value={state?.name ?? ""} onChange={handleNameChange} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>City</FormLabel>
                            <Select value={state?.city?.id ?? ""} onChange={handleCityChange}>
                                {event === undefined && <option value=""></option>}
                                {cities?.map((x) => (
                                    <option key={x.id} value={x.id}>
                                        {x.label.en}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Place</FormLabel>
                            <Select value={state?.place?.id ?? ""} onChange={handlePlaceChange}>
                                {event === undefined && <option value=""></option>}
                                {places?.map((x) => (
                                    <option key={x.id} value={x.id}>
                                        {x.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Event date</FormLabel>
                            <Input
                                type="datetime-local"
                                value={getRFC3339DateString(state?.dateTime)}
                                onChange={handleDateTimeChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Booking start date</FormLabel>
                            <Input
                                type="datetime-local"
                                value={getRFC3339DateString(state?.bookingStartDate)}
                                onChange={handleBookingStartDateChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                minH={150}
                                size="md"
                                resize="none"
                                value={state?.desc ?? ""}
                                onChange={handleDescriptionChange}
                            />
                        </FormControl>
                        <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={10}>
                            <GridItem colSpan={1}>
                                <FormControl>
                                    <FormLabel>Price per person</FormLabel>
                                    <Input
                                        type="number"
                                        min={0}
                                        value={state?.price ?? ""}
                                        onChange={handlePriceChange}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <FormControl>
                                    <FormLabel>Max number of teammates</FormLabel>
                                    <NumberInput
                                        min={0}
                                        size="md"
                                        value={state?.maxTeammatesCount ?? ""}
                                        onChange={handleMaxTeammatesCountChange}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <Grid w="100%" templateColumns="repeat(2, 1fr)" gap={10}>
                            <GridItem colSpan={1}>
                                <FormControl>
                                    <FormLabel>Maximum number of teams</FormLabel>
                                    <NumberInput
                                        min={0}
                                        size="md"
                                        value={state?.maxTeamCount ?? ""}
                                        onChange={handleMaxTeamCountChange}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                                <FormControl>
                                    <FormLabel>Max count of teams in reserve</FormLabel>
                                    <NumberInput
                                        min={0}
                                        size="md"
                                        value={state?.maxReserveCount ?? ""}
                                        onChange={handleMaxReserveCountChange}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <FormControl>
                            <FormLabel>Photo gallery URL</FormLabel>
                            <Input value={state?.photoGalleryUrl ?? ""} onChange={handlePhotoUrlChange} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Photo cover URL</FormLabel>
                            <Input value={state?.photoCoverUrl ?? ""} onChange={handlePhotoCoverUrlChange} />
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Box w="100%" pr="18">
                        {showSuccessMsg && (
                            <Alert status="success">
                                {event === undefined ? "New event was successfully created" : "Update was successfull"}
                            </Alert>
                        )}
                        {showFailedMsg && <Alert status="error">Oops! Error occured! Please, try again!</Alert>}
                    </Box>
                    <Button isDisabled={isSaveDisabled} onClick={createOrUpdateEvent}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
