import {
    Alert,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Switch,
    Textarea,
    VStack,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { adminRepository } from "../../domain/admin/adminRepository";
import { Booking } from "../../domain/booking/booking";
import { bookingRepository } from "../../domain/booking/bookingRepository";
import { BookingType } from "../../domain/booking/bookingType";
import { Event } from "../../domain/event/event";

interface IProps {
    onOpen: () => void;
    onClose: () => void;
    isOpen: boolean;
    booking?: Booking | undefined;
    event?: Event | undefined;
    reloadBookings: (signal?: AbortSignal) => {};
}

interface IState {
    id?: string | undefined;
    eventId?: string | undefined;
    eventVersion?: string | undefined;
    teamName?: string | undefined;
    leaderName?: string | undefined;
    teammatesCount?: number | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    comment?: string | undefined;
    isFirstGame?: boolean;
    hasCertificate?: boolean;
    bookingType?: BookingType | undefined;
    dateOfBooking?: Date | undefined;
    isDeleted?: boolean;
}

export const EditBookingModal: React.FC<IProps> = ({ onOpen, onClose, isOpen, booking, event, reloadBookings }) => {
    const [state, setState] = useState<IState>();
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [showFailedMsg, setShowFailedMsg] = useState(false);

    const initState = (initBooking?: Booking) => {
        setState({
            id: initBooking?.id,
            eventId: initBooking?.eventId,
            eventVersion: initBooking?.eventVersion,
            teamName: initBooking?.teamName,
            leaderName: initBooking?.teamLeaderName,
            teammatesCount: initBooking?.teammatesCount,
            phone: initBooking?.phoneNumber,
            email: initBooking?.email,
            comment: initBooking?.comment,
            isFirstGame: initBooking?.isFirstGame,
            hasCertificate: initBooking?.hasCertificate,
            bookingType: initBooking?.bookingType,
            dateOfBooking: initBooking?.dateOfBooking,
            isDeleted: initBooking?.isDeleted,
        });

        setIsFormDisabled(false);
        setShowFailedMsg(false);
        setShowSuccessMsg(false);
    };

    const handleTeamNameChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setState({ ...state, teamName: val });
    };

    const handleLeaderNameChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setState({ ...state, leaderName: val });
    };

    const handlePhoneChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setState({ ...state, phone: val });
    };

    const handleEmailChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setState({ ...state, email: val });
    };

    const handleCommentChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setState({ ...state, comment: val });
    };

    const handleBookingTypeChange = (e: SyntheticEvent) => {
        const val = Number((e.target as HTMLSelectElement).value);
        const enumStr = BookingType[val];
        setState({ ...state, bookingType: BookingType[enumStr as keyof typeof BookingType] });
    };

    const handleIsFirstGameChange = (e: SyntheticEvent) => {
        setState({ ...state, isFirstGame: (e.target as HTMLInputElement).checked });
    };

    const handleHasCertificateChange = (e: SyntheticEvent) => {
        setState({ ...state, hasCertificate: (e.target as HTMLInputElement).checked });
    };

    const handleCloseModal = () => {
        initState();
        onClose();
    };

    useEffect(() => {
        initState(booking);
    }, []);

    useEffect(() => {
        initState(booking);
    }, [booking]);

    const createorUpdateBooking = async () => {
        setIsFormDisabled(true);
        const response = await adminRepository.createOrUpdateBooking({
            id: state?.id,
            eventId: state?.eventId ?? event?.id,
            eventVersion: state?.eventVersion ?? event?.version,
            teamName: state?.teamName,
            teamLeaderName: state?.leaderName,
            teammatesCount: state?.teammatesCount,
            phoneNumber: state?.phone,
            email: state?.email,
            comment: state?.comment,
            bookingType: state?.bookingType,
            hasBonusCertificate: state?.hasCertificate,
            isFirstGame: state?.isFirstGame,
            dateOfBooking: state?.dateOfBooking?.toISOString() ?? new Date(Date.now()).toISOString(),
            isDeleted: state?.isDeleted,
        });

        // TODO: booking type incorrenct in dto

        if (!response.ok) {
            setShowFailedMsg(true);
            setIsFormDisabled(false);
            return;
        }

        setShowFailedMsg(false);
        setShowSuccessMsg(true);
        reloadBookings();
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
                <ModalOverlay h="100%" w="100%" />
                <ModalContent bgColor="white">
                    <ModalCloseButton />
                    <ModalHeader></ModalHeader>
                    <ModalBody>
                        <VStack w="100%" gap={2}>
                            <FormControl>
                                <FormLabel>Team name</FormLabel>
                                <Input
                                    value={state?.teamName}
                                    onChange={handleTeamNameChange}
                                    isDisabled={isFormDisabled}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Team leader name</FormLabel>
                                <Input
                                    value={state?.leaderName}
                                    onChange={handleLeaderNameChange}
                                    isDisabled={isFormDisabled}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Phone</FormLabel>
                                <Input value={state?.phone} onChange={handlePhoneChange} isDisabled={isFormDisabled} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input value={state?.email} onChange={handleEmailChange} isDisabled={isFormDisabled} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Comment</FormLabel>
                                <Textarea
                                    minH={150}
                                    resize="none"
                                    value={state?.comment}
                                    onChange={handleCommentChange}
                                    isDisabled={isFormDisabled}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Booking type</FormLabel>
                                <Select
                                    value={state?.bookingType}
                                    onChange={handleBookingTypeChange}
                                    isDisabled={isFormDisabled}
                                >
                                    <option value=""></option>
                                    <option value={BookingType.Regular}>Regular</option>
                                    <option value={BookingType.Reserve}>Reserve</option>
                                </Select>
                            </FormControl>
                            <Grid templateColumns="repeat(2, 1fr)" width="100%" gap={5}>
                                <GridItem colSpan={1}>
                                    <Flex gap={3} align="center" direction="row">
                                        <FormLabel alignContent="center">First game</FormLabel>
                                        <Switch
                                            size="md"
                                            isDisabled={isFormDisabled}
                                            checked={state?.isFirstGame}
                                            onChange={handleIsFirstGameChange}
                                        />
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <Flex gap={3} align="center" direction="row">
                                        <FormLabel>Has certificate</FormLabel>
                                        <Switch
                                            size="md"
                                            isDisabled={isFormDisabled}
                                            checked={state?.hasCertificate}
                                            onChange={handleHasCertificateChange}
                                        />
                                    </Flex>
                                </GridItem>
                            </Grid>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Box w="100%" pr="18">
                            {showSuccessMsg && (
                                <Alert status="success">
                                    {booking === undefined
                                        ? "New booking was successfully created"
                                        : "Update was successfull"}
                                </Alert>
                            )}
                            {showFailedMsg && <Alert status="error">Oops! Error occured! Please, try again!</Alert>}
                        </Box>
                        <Button isDisabled={isFormDisabled} onClick={createorUpdateBooking}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
