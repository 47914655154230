import { Text, VStack, HStack, Input, Select, Flex, Box, useBreakpoint } from "@chakra-ui/react";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { intl, IntlKey } from "../../intl-languages";
import { Languages } from "../../intl-languages";
import { LanguageContext } from "../../context/languageContext";
import { CityContext } from "../../domain/city/cityContext";
import { Place } from "../../domain/place/place";
import { Event } from "../../domain/event/event";
import { eventRepository } from "../../domain/event/eventRepository";
import { placeRepository } from "../../domain/place/placeRepository";
import { EventCard } from "../event/eventCard";
import { ChakraDatePicker } from "../datepicker/chakraDatePicker";
import { isMobileScreen } from "../../common/breakPoints";

const ScheduleContainer: React.FC = () => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);

    const { cityArr, city } = useContext(CityContext);

    const [cityId, setCityId] = useState("");
    const [placeId, setPlaceId] = useState("");
    const [dateFrom, setDateFrom] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [pageNumber, setPageNumber] = useState(1);

    const [events, setEvents] = useState([] as Event[]);
    const [places, setPlaces] = useState([] as Place[]);

    const handleCityChange = (e: SyntheticEvent) => {
        setCityId((e.target as HTMLSelectElement).value);
    };

    const handlePlaceChange = (e: SyntheticEvent) => {
        setPlaceId((e.target as HTMLSelectElement).value);
    };

    const loadEvents = async (signal: AbortSignal) => {
        const eventsResponse = await eventRepository.getEvents(cityId, placeId, dateFrom, dateTo, pageNumber, signal);
        setEvents(
            eventsResponse.ok && eventsResponse.data !== undefined
                ? eventsResponse.data.filter((x) => x.date >= new Date())
                : ([] as Event[])
        );
    };

    const loadPlaces = async (signal: AbortSignal) => {
        const placesRes = await placeRepository.getPlacesByCity(cityId ?? "", false, signal);
        if (placesRes.ok && placesRes.data !== undefined) {
            setPlaces(placesRes.data);
            setPlaceId(placesRes.data[0].id);
        }
    };

    useEffect(() => {
        const now = new Date(Date.now());
        const year = now.getFullYear();
        const month = now.getMonth();
        const initDateFrom = new Date(year, month, 1);
        setDateFrom(initDateFrom);

        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const initDateTo = new Date(year, month, daysInMonth, 23, 59, 59);
        setDateTo(initDateTo);
    }, []);

    useEffect(() => {
        setCityId(city ? city.id : "");
    }, [city, cityArr]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        loadPlaces(signal);
        return () => {
            controller.abort();
        };
    }, [cityId]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        loadEvents(signal);

        return () => {
            controller.abort();
        };
    }, [placeId, dateFrom, dateTo]);

    return (
        <Box
            bg="quiz_purple.50"
            w="100%"
            backgroundImage="url('/assets/schedule_bg.png')"
            backgroundPosition="center"
            backgroundRepeat="repeat-y"
            backgroundSize="cover"
            minH={isMobile ? 850 : 750}
            pb={370}
        >
            <Flex w="100%" align="center" justify="center" p="3%">
                <HStack
                    w="4xl"
                    borderRadius={8}
                    bgColor="quiz_purple.500"
                    color="whiteAlpha.900"
                    pt={3}
                    pb={3}
                    pl="3%"
                    pr="3%"
                    gap={5}
                    boxShadow="2xl"
                >
                    <VStack w="100%" align="left">
                        <Text fontSize="xs">{intl.scheduleFilterCity[langKey]}</Text>
                        <Select variant="outline" value={cityId} onChange={handleCityChange}>
                            {cityArr?.map((x) => (
                                <option key={x.id} value={x.id}>
                                    {x.label[langKey]}
                                </option>
                            ))}
                        </Select>
                    </VStack>
                    <VStack w="100%" align="left">
                        <Text fontSize="xs">{intl.scheduleFilterPlace[langKey]}</Text>
                        <Select variant="outline" value={placeId} onChange={handlePlaceChange}>
                            {places.map((x) => (
                                <option key={x.id} value={x.id}>
                                    {x.name}
                                </option>
                            ))}
                        </Select>
                    </VStack>
                    <VStack w="100%" align="left">
                        <Text fontSize="xs">{intl.scheduleFilterDate[langKey]}</Text>
                        <ChakraDatePicker
                            setDateFrom={setDateFrom}
                            dateFrom={dateFrom}
                            setDateTo={setDateTo}
                            dateTo={dateTo}
                            inputVariant="outline"
                        />
                    </VStack>
                </HStack>
            </Flex>
            <Flex p="3%" justify="center" align="center" gap={8} wrap="wrap" zIndex={99}>
                {events.map((x) => {
                    return <EventCard key={x.id} event={x} />;
                })}
            </Flex>
        </Box>
    );
};

export default ScheduleContainer;
