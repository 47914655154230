import {
    Flex,
    Grid,
    GridItem,
    IconButton,
    Input,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LanguageContext } from "../../context/languageContext";
import { eventRepository } from "../../domain/event/eventRepository";
import { IntlKey, WeekdayShortNames, LanguageCode, Languages } from "../../intl-languages";

interface IProps {
    setDateFrom: (date: Date) => void;
    dateFrom: Date;
    setDateTo: (date: Date) => void;
    dateTo: Date;
    inputVariant: string;
}

export const ChakraDatePicker: React.FC<IProps> = ({ dateFrom, setDateFrom, dateTo, setDateTo, inputVariant }) => {
    const { isOpen, onToggle, onClose } = useDisclosure();

    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const langCode = LanguageCode.getCode(lang);

    const [days, setDays] = useState<string[]>();
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [datesCount, setDatesCount] = useState(2);
    const [eventsDatesByMonth, setEventsDatesByMonth] = useState<Date[]>([]);

    // Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
    // but by using 0 as the day it will give us the last day of the prior
    // month. So passing in 1 as the month number will return the last day
    // of January, not February
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    useEffect(() => {
        setYear(dateFrom.getFullYear());
        setMonth(dateFrom.getMonth());
    }, []);

    useEffect(() => {
        let days: string[] = [];

        for (let i = 1; i <= daysInMonth; i++) {
            days.push(i.toString());
        }

        const firstWeekdayOfMonth = new Date(year, month, 1).getDay();
        for (let i = 0; i < firstWeekdayOfMonth; i++) days.unshift("");

        setDays(days);
    }, [year, month]);

    const loadEventsDates = async (signal: AbortSignal) => {
        const firstDate = new Date(year, month, 1);
        const lastDay = new Date(year, month, daysInMonth, 23, 59, 59);

        const datesRes = await eventRepository.getEventsDatesByPeriod(firstDate, lastDay, signal);
        if (datesRes.ok && datesRes.data !== undefined)
            setEventsDatesByMonth(datesRes.data.filter((x) => x >= new Date()));
    };

    useEffect(() => {
        const controller = new AbortController();
        loadEventsDates(controller.signal);
        return () => {
            controller.abort();
        };
    }, [month]);

    const handleNextMonth = () => {
        if (month === 11) {
            setMonth(0);
            setYear(year + 1);
        } else {
            setMonth(month + 1);
        }
    };

    const handlePrevMonth = () => {
        if (month === 0) {
            setMonth(11);
            setYear(year - 1);
        } else {
            setMonth(month - 1);
        }
    };

    const handleDaySelect = (val: string) => {
        const day = Number(val);
        const date = new Date(year, month, day);
        if (datesCount === 1) {
            if (date.getTime() < dateFrom.getTime()) {
                setDateTo(formatDateTo(dateFrom));
                setDateFrom(date);
            } else {
                setDateTo(formatDateTo(date));
            }

            setDatesCount(2);
            return;
        }

        if (datesCount === 2) {
            setDateFrom(date);
            setDateTo(formatDateTo(date));
            setDatesCount(1);
            return;
        }
    };

    const dateIsBetween = (dayStr: string) => {
        const day = Number(dayStr);
        if (day === 0) return false;

        const curr = new Date(year, month, day).getTime();
        return (
            (curr >= dateFrom.getTime() && curr <= dateTo.getTime()) ||
            (curr >= dateTo.getTime() && curr <= dateFrom.getTime())
        );
    };

    const isSelectedDate = (dayStr: string, date: Date) => {
        const day = Number(dayStr);

        return date.getDate() === day && date.getMonth() === month && date.getFullYear() === year;
    };

    const isInAvailableDates = (dayStr: string) => {
        const day = Number(dayStr);
        if (eventsDatesByMonth.length === 0) return false;
        return (
            eventsDatesByMonth.findIndex(
                (x) => x.getDate() === day && x.getMonth() === month && x.getFullYear() === year
            ) !== -1
        );
    };

    const formatDateTo = (date: Date) => {
        const dateTo = new Date(date.getTime());
        dateTo.setHours(23);
        dateTo.setMinutes(59);
        dateTo.setSeconds(59);

        return dateTo;
    };

    // DEBUG
    // useEffect(() => {
    //     console.log(`Date from: ${dateFrom}`);
    //     console.log(`Date to: ${dateTo}`);
    // }, [dateFrom.getTime(), dateTo.getTime()]);

    const formatOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    return (
        <Popover isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
                <Input
                    variant={inputVariant}
                    onClick={onToggle}
                    value={`${dateFrom.toLocaleDateString(langCode, formatOptions)} - ${dateTo.toLocaleDateString(
                        langCode,
                        formatOptions
                    )}`}
                    onChange={() => {}}
                />
            </PopoverTrigger>
            <PopoverContent color="black" m={0}>
                <PopoverHeader>
                    <Flex direction="row" justify="space-between" align="center">
                        <IconButton
                            colorScheme="quiz_purple"
                            aria-label="previous month"
                            icon={<FiChevronLeft />}
                            onClick={handlePrevMonth}
                        />
                        <Text>
                            {new Intl.DateTimeFormat(langCode, { month: "long" }).format(new Date(year, month)) +
                                " " +
                                year}
                        </Text>
                        <IconButton
                            colorScheme="quiz_purple"
                            aria-label="next month"
                            icon={<FiChevronRight />}
                            onClick={handleNextMonth}
                        />
                    </Flex>
                </PopoverHeader>
                <PopoverBody>
                    <Grid
                        templateColumns="repeat(7, 1fr)"
                        templateRows="repeat(5, 1fr)"
                        alignContent="center"
                        textAlign="center"
                    >
                        <GridItem colSpan={1} rowSpan={1} color="quiz_red.900">
                            {WeekdayShortNames.Sunday[langKey]}
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={1}>
                            {WeekdayShortNames.Monday[langKey]}
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={1}>
                            {WeekdayShortNames.Tuesday[langKey]}
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={1}>
                            {WeekdayShortNames.Wednesday[langKey]}
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={1}>
                            {WeekdayShortNames.Thursday[langKey]}
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={1}>
                            {WeekdayShortNames.Friday[langKey]}
                        </GridItem>
                        <GridItem colSpan={1} rowSpan={1}>
                            {WeekdayShortNames.Saturday[langKey]}
                        </GridItem>
                        {days?.map((val, index) => {
                            return (
                                <GridItem
                                    key={`${val}-${index}-${month}`}
                                    colSpan={1}
                                    rowSpan={1}
                                    color={
                                        index % 7 === 0 && isInAvailableDates(val)
                                            ? "quiz_red.900"
                                            : index % 7 === 0 && !isInAvailableDates(val)
                                            ? "quiz_red.300"
                                            : !isInAvailableDates(val)
                                            ? "blackAlpha.300"
                                            : ""
                                    }
                                    bgColor={
                                        isSelectedDate(val, dateFrom) || isSelectedDate(val, dateTo)
                                            ? "quiz_purple.400"
                                            : dateIsBetween(val)
                                            ? "quiz_purple.200"
                                            : ""
                                    }
                                    borderLeftRadius={isSelectedDate(val, dateFrom) ? 7 : 0}
                                    borderRightRadius={isSelectedDate(val, dateTo) ? 7 : 0}
                                    onClick={() => {
                                        if (isInAvailableDates(val)) handleDaySelect(val);
                                    }}
                                    _hover={isInAvailableDates(val) ? { cursor: "pointer" } : { cursos: "not-allowed" }}
                                >
                                    {val}
                                </GridItem>
                            );
                        })}
                    </Grid>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
