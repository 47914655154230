import { authToken } from "../auth/authToken";
import { IResponseData } from "../common/response";

export const postWithAuth = async <T>(path: string, body: string, signal?: AbortSignal): Promise<IResponseData<T>> => {
    try {
        const token = authToken.getToken();
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("Accept", "application/json");
        requestHeaders.set("Content-Type", "application/json");
        if (token) requestHeaders.set("Authorization", `Bearer ${token}`);

        const response = await fetch(process.env.REACT_APP_BACKEND_URL + path, {
            method: "POST",
            headers: requestHeaders,
            body: body,
            signal: signal,
        });

        if (!response.ok) {
            return { ok: false, message: response.statusText };
        }

        authToken.renewToken(response);

        const data = (await response.json().catch(() => ({}))) as T;
        return { ok: true, message: "", data: data };
    } catch (ex) {
        console.error(ex);
        return { ok: false, message: (ex as Error).message };
    }
};

export const getWithAuth = async <T>(path: string, params?: {}, signal?: AbortSignal): Promise<IResponseData<T>> => {
    try {
        const token = authToken.getToken();

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("Accept", "application/json");
        requestHeaders.set("Content-Type", "application/json");
        if (token) requestHeaders.set("Authorization", `Bearer ${token}`);

        const response = await fetch(process.env.REACT_APP_BACKEND_URL + path + "?" + new URLSearchParams(params), {
            method: "GET",
            headers: requestHeaders,
            signal: signal,
        });

        if (!response.ok) {
            return { ok: false, message: response.statusText };
        }

        authToken.renewToken(response);

        const data = (await response.json().catch(() => ({}))) as T;
        return { ok: true, message: "", data: data };
    } catch (ex) {
        console.error(ex);
        return { ok: false, message: (ex as Error).message };
    }
};
