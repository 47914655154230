import React, { Component, ReactNode } from "react";
import { Box, Button, Container, Icon } from "@chakra-ui/react";
// Here we have used react-icons package for the icons
import { HiArrowCircleLeft, HiArrowCircleRight } from "react-icons/hi";
// And react-slick as our Carousel Lib
import Slider, { CustomArrowProps } from "react-slick";

interface IProps {
    children?: ReactNode;
    isMobile?: boolean;
}

const NextArrow = (props: CustomArrowProps) => {
    const { className, style, onClick } = props;
    return (
        <Icon
            position="absolute"
            top="50%"
            right={-10}
            as={HiArrowCircleRight}
            onClick={onClick}
            display="block"
            color="quiz_purple.600"
            zIndex={99}
            boxSize={6}
            _active={{ color: "quiz_purple.700" }}
        />
    );
};

const PrevArrow = (props: CustomArrowProps) => {
    const { className, style, onClick } = props;
    return (
        <Icon
            as={HiArrowCircleLeft}
            color="quiz_purple.500"
            onClick={onClick}
            display="block"
            position="absolute"
            top="50%"
            left={-10}
            zIndex={99}
            boxSize={6}
            _active={{ color: "quiz_purple.700" }}
        />
    );
};

export default class CustomSlider extends Component<IProps> {
    render() {
        const childrenCount = React.Children.toArray(this.props.children).length;
        const settings = {
            dots: false,
            //arrows: true,
            //fade: true,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 5000,
            infinite: true,
            slidesToShow: this.props.isMobile ? 1 : childrenCount > 3 ? 3 : childrenCount,
            slidesToScroll: this.props.isMobile ? 1 : childrenCount > 3 ? 3 : childrenCount,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        };
        return (
            <Box w="80%">
                <Slider {...settings}>{this.props.children}</Slider>
            </Box>
        );
    }
}
