import { VStack, Heading, Center, Box, useBreakpoint, Text, Divider, Tag, Button, Flex } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { isMobileScreen } from "../../common/breakPoints";
import { LanguageContext } from "../../context/languageContext";
import { CityContext } from "../../domain/city/cityContext";
import { Event } from "../../domain/event/event";
import { eventRepository } from "../../domain/event/eventRepository";
import { getGoogleDriveLink } from "../../domain/utils/googleDriveLink";
import { intl, IntlKey, Languages } from "../../intl-languages";
import CustomSlider from "./slider/slider";

export const LastEventsContainer = () => {
    const { city } = useContext(CityContext);
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);

    const [events, setEvents] = useState<Event[]>();

    const loadLastEvents = async (signal: AbortSignal) => {
        const response = await eventRepository.getLastEventsByCityId(city?.id);
        if (response.ok) setEvents(response.data);
    };

    useEffect(() => {
        const controller = new AbortController();
        loadLastEvents(controller.signal);

        return () => {
            controller.abort();
        };
    }, [city]);

    return (
        <VStack w="100%" h="500px" bgColor="quiz_purple.50" pr={3} pl={3} gap={5} minH={isMobile ? 850 : 750} pb={270}>
            <Center pt={5}>
                <Heading color="quiz_blue.900">{intl.lastEvents[langKey]}</Heading>
            </Center>
            <CustomSlider isMobile={isMobile}>
                {events?.map((x) => {
                    return (
                        <VStack key={x.id} p={2}>
                            <Box
                                h={isMobile ? 72 : 60}
                                backgroundImage={getGoogleDriveLink(x.photoCoverUrl)}
                                backgroundPosition="center"
                                backgroundRepeat="no-repeat"
                                backgroundSize="cover"
                                borderRadius={16}
                            />
                            <Text fontWeight="bold" fontSize="xl">
                                {x.name}
                            </Text>
                            <Divider />
                            <Flex wrap="wrap" direction="row" gap={2}>
                                <Tag variant="outline" colorScheme="quiz_purple">
                                    {x.date.toLocaleDateString()}
                                </Tag>
                                <Tag variant="outline" colorScheme="quiz_purple">
                                    {x.place.name}
                                </Tag>
                                <Button
                                    variant="solid"
                                    colorScheme="quiz_purple"
                                    size="xs"
                                    rightIcon={<FiExternalLink />}
                                    disabled={x.photoGalleryUrl.length == 0}
                                    onClick={() => window.open(x.photoGalleryUrl)}
                                >
                                    {intl.lastEventsOtherPhotos[langKey]}
                                </Button>
                            </Flex>
                        </VStack>
                    );
                })}
            </CustomSlider>
        </VStack>
    );
};
