import React, { useContext } from "react";
import {
    Flex,
    Spacer,
    Box,
    Button,
    useBreakpoint,
    Menu,
    MenuButton,
    IconButton,
    MenuItem,
    MenuList,
    Image,
    useDisclosure,
} from "@chakra-ui/react";
import LanguageSelector from "./languageSelector";
import { Languages } from "../../intl-languages";
import { isMobileScreen } from "../../common/breakPoints";
import { AiOutlineMenu } from "react-icons/ai";
import { CitySelector } from "./citySelector";
import { LanguageContext } from "../../context/languageContext";
import { intl, IntlKey } from "../../intl-languages";
import { Link } from "react-router-dom";
import { RulesModal } from "../about/rulesModal";

const Navbar: React.FC = () => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const paddingLeftRight = isMobile ? 5 : 20;

    return (
        <Box w="100%" bg="white.900" color="white" pl={paddingLeftRight} pr={paddingLeftRight} pt={3} pb={3}>
            <Flex w="100%" gap={2} align="center">
                <Box maxW={isMobile ? 100 : 150}>
                    <Link to="/">
                        <Image src="/assets/logo.svg" />
                    </Link>
                </Box>
                <Spacer />
                {isMobile ? (
                    <Flex align="center" gap={2}>
                        <CitySelector />
                        <LanguageSelector />
                        <Menu variant="mainMenu">
                            <MenuButton
                                as={IconButton}
                                icon={<AiOutlineMenu />}
                                variant="outline"
                                _hover={{ bg: "quiz_purple.100" }}
                                _active={{ bg: "quiz_purple.200" }}
                                color="quiz_purple.500"
                                borderWidth={1}
                                borderColor="quiz_purple.500"
                                size="sm"
                            ></MenuButton>
                            <MenuList>
                                <Link to="/schedule">
                                    <MenuItem>{intl.navSchedule[langKey]}</MenuItem>
                                </Link>
                                {/* <MenuItem>{intl.navRanking[langKey]}</MenuItem> */}
                                <MenuItem onClick={onOpen}>{intl.navAbout[langKey]}</MenuItem>
                                {/* <MenuItem>{intl.navContacts[langKey]}</MenuItem> */}
                            </MenuList>
                        </Menu>
                    </Flex>
                ) : (
                    <Flex align="center" gap={2}>
                        <Link to="/schedule">
                            <Button size="sm" variant="outline" colorScheme="navbar_purple">
                                {intl.navSchedule[langKey]}
                            </Button>
                        </Link>
                        {/* <Button size="sm" variant="outline" colorScheme="navbar_purple">
                            {intl.navRanking[langKey]}
                        </Button> */}
                        <Button size="sm" variant="outline" colorScheme="navbar_purple" onClick={onOpen}>
                            {intl.navAbout[langKey]}
                        </Button>
                        {/* <Button size="sm" variant="outline" colorScheme="navbar_purple">
                            {intl.navContacts[langKey]}
                        </Button> */}
                        <Flex
                            align="center"
                            borderLeftWidth={1}
                            borderLeftColor="quiz_purple.600"
                            ml={5}
                            pl={7}
                            gap={2}
                        >
                            <CitySelector />
                            <LanguageSelector />
                        </Flex>
                    </Flex>
                )}
            </Flex>
            <RulesModal lang={lang} isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        </Box>
    );
};

export default Navbar;
