import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { adminRepository } from "../../domain/admin/adminRepository";
import { City } from "../../domain/city/city";
import { Place } from "../../domain/place/place";
import { placeRepository } from "../../domain/place/placeRepository";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    place?: Place;
    city?: City;
    loadPlaces: () => void;
}

interface IState {
    id?: string;
    cityId?: string;
    name?: string;
    address?: string;
    geoTagUrl?: string;
    isDeleted?: boolean;
}

export const EditPlaceModal: React.FC<IProps> = ({ isOpen, onOpen, onClose, place, city, loadPlaces }) => {
    const [state, setState] = useState<IState>();

    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [showFailureMsg, setShowFailureMsg] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const initState = () => {
        setState({
            id: place?.id,
            cityId: place?.cityId ?? city?.id,
            name: place?.name,
            address: place?.address,
            geoTagUrl: place?.geoTagUrl,
            isDeleted: place?.isDeleted,
        });
    };

    const handleCloseModal = () => {
        setState({
            id: "",
            cityId: city?.id,
            name: "",
            address: "",
            geoTagUrl: "",
            isDeleted: false,
        });

        onClose();
        setIsFormDisabled(false);
        setShowFailureMsg(false);
        setShowSuccessMsg(false);
    };

    const handleNameChange = (e: SyntheticEvent) => {
        setState({ ...state, name: (e.target as HTMLInputElement).value });
    };

    const handleAddresChange = (e: SyntheticEvent) => {
        setState({ ...state, address: (e.target as HTMLInputElement).value });
    };

    const handleGeoTagUrlChange = (e: SyntheticEvent) => {
        setState({ ...state, geoTagUrl: (e.target as HTMLInputElement).value });
    };

    const handleSave = async () => {
        setIsFormDisabled(true);

        const response = await adminRepository.createOrUpdatePlace({
            id: state?.id,
            cityId: city?.id,
            name: state?.name,
            address: state?.address,
            geoTagUrl: state?.geoTagUrl,
            isDeleted: state?.isDeleted,
        });

        if (!response.ok) {
            setShowFailureMsg(true);
            setShowSuccessMsg(false);
            setIsFormDisabled(false);
            return;
        }

        setShowFailureMsg(false);
        setShowSuccessMsg(true);
        loadPlaces();
    };

    useEffect(() => {
        initState();
    }, [place]);

    useEffect(() => {
        initState();
    }, []);

    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal}>
            <ModalOverlay h="100%" w="100%" />
            <ModalContent bgColor="white">
                <ModalCloseButton />
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <VStack gap={5}>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input value={state?.name} onChange={handleNameChange} isDisabled={isFormDisabled} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Address</FormLabel>
                            <Input value={state?.address} onChange={handleAddresChange} isDisabled={isFormDisabled} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Geo tag URL</FormLabel>
                            <Input
                                value={state?.geoTagUrl}
                                onChange={handleGeoTagUrlChange}
                                isDisabled={isFormDisabled}
                            />
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Box w="100%" pr="18">
                        {showSuccessMsg && (
                            <Alert status="success">
                                {place === undefined ? "New place was successfully created" : "Update was successfull"}
                            </Alert>
                        )}
                        {showFailureMsg && <Alert status="error">Oops! Error occured! Please, try again!</Alert>}
                    </Box>
                    <Button isDisabled={isFormDisabled} onClick={handleSave}>
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
