import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../common/authRedirect";

interface IProps {
    children?: React.ReactNode;
}

export const AdminAuthWrapper: React.FC<IProps> = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const controller = new AbortController();
        auth(navigate, controller.signal);

        return () => {
            controller.abort();
        };
    }, []);
    return <>{children}</>;
};
