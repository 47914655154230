import {
    Heading,
    Text,
    Flex,
    Button,
    Grid,
    GridItem,
    Box,
    useBreakpoint,
    Spacer,
    Image,
    HStack,
    Tag,
    TagLeftIcon,
    TagLabel,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { FiClock, FiMapPin, FiAlertCircle } from "react-icons/fi";
import { Languages } from "../../intl-languages";
import { LanguageContext } from "../../context/languageContext";
import { intl, IntlKey, LanguageCode } from "../../intl-languages";
import { Event } from "../../domain/event/event";
import { BookEventModal } from "../booking/bookEventModal";
import { useDisclosure } from "@chakra-ui/react";
import { isMobileScreen } from "../../common/breakPoints";

interface IProps {
    event: Event;
}

export const EventCard: React.FC<IProps> = ({ event }) => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const langCode = LanguageCode.getCode(lang);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);

    const isFewPlacesLeft = event.placesLeft > 0 && event.placesLeft <= 5;
    const isWaitingListAvailable = event.fallbackPlacesLeft > 0 && event.fallbackPlacesLeft <= 5;
    const anyReservUnavailable = event.placesLeft === 0 && event.fallbackPlacesLeft === 0;

    const weekday = new Intl.DateTimeFormat(langCode, { weekday: "long" }).format(event.date);
    const month = new Intl.DateTimeFormat(langCode, { month: "long" }).format(event.date);
    const dayDate = event.date.getDate();

    const hours = event.date.getHours();
    const rawMinutes = event.date.getMinutes().toString();
    const formatedMinutes = rawMinutes.length < 2 ? `0${rawMinutes}` : rawMinutes;

    return (
        <>
            <Box id={event.id} key={event.id} maxW="xs" color="whiteAlpha.900" fontSize="sm">
                <Grid
                    templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(1, 1fr)"
                    bg="quiz_purple.900"
                    borderRadius={16}
                    p={6}
                >
                    <GridItem colSpan={1} rowSpan={1}>
                        <Flex direction="row" align="center" gap={3}>
                            <Image
                                boxSize={isMobile ? "5em" : "7em"}
                                objectFit="contain"
                                src="/assets/event_illustrations/classic.svg"
                            />
                            <Flex w="100%" direction="column" gap={2} align="left">
                                <Flex
                                    justify="center"
                                    align="center"
                                    maxW={90}
                                    borderRadius={8}
                                    borderWidth={1}
                                    borderColor="quiz_yellow.300"
                                    bg="quiz_yellow.50"
                                    p={1}
                                    fontSize={isMobile ? "xs" : ""}
                                >
                                    <HStack color="quiz_yellow.300" fontWeight="bold">
                                        <FiClock />
                                        <Text>
                                            {hours}:{formatedMinutes}
                                        </Text>
                                    </HStack>
                                </Flex>
                                <Text
                                    fontSize={isMobile ? "lg" : "2xl"}
                                    fontWeight="bold"
                                    lineHeight={7}
                                >
                                    {event.name}
                                </Text>
                            </Flex>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={1} rowSpan={1} pt={3}>
                        <Text>{event.description}</Text>
                    </GridItem>
                </Grid>
                <Grid
                    templateColumns="repeat(8, 1fr)"
                    templateRows="repeat(3, 1fr)"
                    bg="quiz_purple.900"
                    borderRadius={16}
                    pr={6}
                    pl={6}
                    pb={4}
                    pt={6}
                    gap={4}
                >
                    <GridItem colSpan={3} rowSpan={2}>
                        <Box h="100%" borderRadius={8} borderWidth={1} borderColor="quiz_pink.600">
                            <Flex h="100%" justify="center" direction="column">
                                <Text fontSize="0.8em" textAlign="center" pt="3%" mb="-5%">
                                    {month}
                                </Text>
                                <Text fontSize="4em" as="b" textAlign="center">
                                    {dayDate}
                                </Text>
                                <Text fontSize="1em" mt="-2.5" textAlign="center" pb="3%">
                                    {weekday}
                                </Text>
                            </Flex>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={5} rowSpan={2}>
                        <Flex direction="column" align="left" justify="space-between" h="100%">
                            <HStack color="quiz_yellow.400" fontWeight="bold">
                                <FiMapPin />
                                <Text>{event.place.name}</Text>
                            </HStack>
                            <Text>{event.place.address}</Text>
                            {isFewPlacesLeft && (
                                <Tag color="whiteAlpha.900" bg="quiz_pink.600">
                                    <TagLeftIcon as={FiAlertCircle} />
                                    <TagLabel as="b">{intl.eventCardFewPlacesLeft[langKey]}</TagLabel>
                                </Tag>
                            )}
                            <Button
                                bg="quiz_cyan.40010"
                                borderWidth={1}
                                borderColor="quiz_cyan.400"
                                color="quiz_cyan.400"
                                size="xs"
                                _hover={{ bg: "quiz_cyan.40020" }}
                                _active={{ bg: "quiz_cyan.40050" }}
                                onClick={() => {
                                    window.open(event.place.geoTagUrl);
                                }}
                            >
                                {intl.eventCardWhere[langKey]}
                            </Button>
                        </Flex>
                    </GridItem>
                    <GridItem colSpan={8} rowSpan={1}>
                        <Flex w="100%" direction="row" justify="space-between" align="center">
                            <Box>
                                <Heading fontSize={isMobile ? "2xl" : "xl"}>{event.price} ₩</Heading>
                                <Text fontSize="xs">/{intl.eventCardPerPerson[langKey]}</Text>
                            </Box>
                            <Button
                                colorScheme={anyReservUnavailable ? "quiz_yellow" : "quiz_red_btn"}
                                color="whiteAlpha.900"
                                disabled={event.placesLeft === 0 && event.fallbackPlacesLeft === 0}
                                onClick={onOpen}
                            >
                                {event.placesLeft > 0
                                    ? intl.eventCardBook[langKey]
                                    : isWaitingListAvailable
                                    ? intl.eventCardReserveWaitingList[langKey]
                                    : intl.eventCardNoPlacesLeft[langKey]}
                            </Button>
                        </Flex>
                    </GridItem>
                </Grid>
            </Box>
            <BookEventModal
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                event={event}
                dateFormated={{
                    month: month,
                    weekday: weekday,
                    dayDate: dayDate.toString(),
                    hours: hours.toString(),
                    minutes: formatedMinutes,
                }}
            />
        </>
    );
};
