import { IResponse, IResponseData } from "../common/response";
import { getWithAuth, postWithAuth } from "../utils/baseFetch";
import { Booking, getBookingFromDto } from "./booking";
import { BookingDto } from "./dto/bookingDto";
import { CreateUpdateBookingDto } from "./dto/createUpdateBooking";

export class BookingRepository {
    public async createOrUpdate(dto: CreateUpdateBookingDto): Promise<IResponse> {
        try {
            const response = await postWithAuth("/api/booking/createUpdate", JSON.stringify(dto));

            if (!response.ok)
                return {
                    ok: false,
                    message: `Error occured on booking/create: ${response.message}`,
                };

            return { ok: true, message: "" };
        } catch (ex) {
            console.error(ex);
            return { ok: false, message: (ex as Error).message };
        }
    }

    public async getBookingsByEventId(
        eventId: string,
        showDeleted: boolean,
        signal?: AbortSignal
    ): Promise<IResponseData<Booking[]>> {
        try {
            if (eventId === "") return { ok: false, message: "Wrong eventId!" };

            const response = await getWithAuth<BookingDto[]>(
                "/api/booking/getBookingsByEventId",
                { eventId: eventId, showDeleted: `${showDeleted}` },
                signal
            );

            if (!response.ok)
                return {
                    ok: false,
                    message: `Error occured during /api/booking/getBookingsByEventId: ${response.message}`,
                };

            return { ok: true, data: response.data?.map((x) => getBookingFromDto(x)), message: "" };
        } catch (ex) {
            console.error(ex);
            return { ok: false, message: (ex as Error).message };
        }
    }

    public async getTeamNamesFromBookings(eventId: string, signal?: AbortSignal): Promise<IResponseData<string[]>> {
        try {
            const response = await getWithAuth<string[]>(
                "/api/booking/getTeamNamesFromOtherBookings",
                { eventId: eventId },
                signal
            );

            if (!response.ok)
                return { ok: false, message: "Error occured during /api/booking/getTeamNamesFromOtherBookings" };

            return { ok: true, data: response.data, message: "" };
        } catch (ex) {
            console.error(ex);
            return { ok: false, message: (ex as Error).message };
        }
    }
}

const instance = new BookingRepository();
export { instance as bookingRepository };
