import { Box, Center, Heading, Flex, Button, useBreakpoint, Skeleton, HStack } from "@chakra-ui/react";
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { isMobileScreen } from "../../common/breakPoints";
import { LanguageContext } from "../../context/languageContext";
import { CityContext } from "../../domain/city/cityContext";
import { Event } from "../../domain/event/event";
import { eventRepository } from "../../domain/event/eventRepository";
import { intl, IntlKey, Languages } from "../../intl-languages";
import { EventCard } from "../event/eventCard";

const UpcomingEventsContainer: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);
    const { city } = useContext(CityContext);
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    const [upcomingEvents, setUpcomingEvents] = useState([] as Event[]);
    const [loadCompleted, setLoadCompleted] = useState(false);

    const loadUpcomingEvents = async (signal: AbortSignal) => {
        if (city === undefined) return;
        const eventsRes = await eventRepository.getUpcomingEvents(city.id, signal);
        if (eventsRes.ok && eventsRes.data !== undefined) {
            setUpcomingEvents(eventsRes.data);
            setLoadCompleted(true);
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        setLoadCompleted(false);
        loadUpcomingEvents(signal);

        return () => {
            controller.abort();
        };
    }, [city]);

    return (
        <Box w="100%" minH="700" bgColor="quiz_purple.50" color="whiteAlpha.900" pb="5">
            <Box
                w="100%"
                h="100%"
                backgroundImage="url('/assets/blob.png')"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
            >
                <Box w="100%">
                    <Center pt={5}>
                        <Heading color="quiz_blue.900">{intl.upcomingEventsHeading[langKey]}</Heading>
                    </Center>
                    <Flex
                        gap={3}
                        p={isMobile ? "10%" : "15%"}
                        pt="1%"
                        pb="2%"
                        justify="center"
                        align="center"
                        wrap="wrap"
                    >
                        {loadCompleted ? (
                            upcomingEvents.map((x) => {
                                return <EventCard key={x.id} event={x} />;
                            })
                        ) : (
                            <Flex w="100%" h="100%" direction="row" gap={5} wrap="wrap">
                                <Skeleton borderRadius={8} startColor="quiz_purple.50010" endColor="quiz_purple.500">
                                    <Box w="xs" h="sm" />
                                </Skeleton >
                                <Skeleton borderRadius={8} startColor="quiz_purple.50010" endColor="quiz_purple.500">
                                    <Box w="xs" h="sm" borderRadius={16} />
                                </Skeleton>
                                <Skeleton borderRadius={8} startColor="quiz_purple.50010" endColor="quiz_purple.500">
                                    <Box w="xs" h="sm" borderRadius={16} />
                                </Skeleton >
                                <Skeleton borderRadius={8} startColor="quiz_purple.50010" endColor="quiz_purple.500">
                                    <Box w="xs" h="sm" borderRadius={16} />
                                </Skeleton>
                                <Skeleton borderRadius={8} startColor="quiz_purple.50010" endColor="quiz_purple.500">
                                    <Box w="xs" h="sm" borderRadius={16} />
                                </Skeleton>
                                <Skeleton borderRadius={8} startColor="quiz_purple.50010" endColor="quiz_purple.500">
                                    <Box w="xs" h="sm" borderRadius={16} />
                                </Skeleton>
                            </Flex>
                        )}
                    </Flex>
                    <Center pt={3}>
                        <Link
                            to="/schedule"
                            onClick={() => {
                                window.scroll(0, 0);
                            }}
                        >
                            <Button
                                boxShadow="2xl"
                                variant="solid"
                                bg="#8900F4"
                                bgGradient="linear(to-br, #8900F4, #F400BF)"
                                _hover={{ bg: "#8900F4" }}
                                _active={{ bg: "#F400BF" }}
                                color="whiteAlpha.900"
                            >
                                {intl.upcomingEventsShowMore[langKey]}
                            </Button>
                        </Link>
                    </Center>
                </Box>
            </Box>
        </Box>
    );
};

export default UpcomingEventsContainer;
