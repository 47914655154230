import {
    Box,
    Button,
    Center,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Select,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FiEdit, FiMoreVertical, FiPlus, FiPlusCircle, FiRefreshCw, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { adminRepository } from "../../domain/admin/adminRepository";
import { Booking } from "../../domain/booking/booking";
import { bookingRepository } from "../../domain/booking/bookingRepository";
import { BookingType } from "../../domain/booking/bookingType";
import { Event } from "../../domain/event/event";
import { eventRepository } from "../../domain/event/eventRepository";
import { AdminAuthWrapper } from "./adminAuthWrapper";
import { EditBookingModal } from "./editBookingModal";

export const AdminBookings: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [showDeleted, setShowDeleted] = useState(false);
    const [events, setEvents] = useState<Event[]>();
    const [bookings, setBookings] = useState<Booking[]>();
    const [event, setEvent] = useState<Event>();
    const [bookingOnEdit, setBookingOnEdit] = useState<Booking>();

    const handleShowDeleted = (e: SyntheticEvent) => {
        setShowDeleted((e.target as HTMLInputElement).checked);
    };

    const handleEventChange = (e: SyntheticEvent) => {
        const eventId = (e.target as HTMLSelectElement).value;
        setEvent(events?.find((x) => x.id === eventId));
    };

    const handleBookingEdit = (booking?: Booking) => {
        setBookingOnEdit(booking);
        onOpen();
    };

    const handleRestoreBooking = async (booking: Booking) => {
        const response = await adminRepository.createOrUpdateBooking({
            id: booking?.id,
            eventId: booking?.eventId ?? event?.id,
            eventVersion: booking?.eventVersion ?? event?.version,
            teamName: booking?.teamName,
            teamLeaderName: booking?.teamLeaderName,
            teammatesCount: booking?.teammatesCount,
            phoneNumber: booking?.phoneNumber,
            email: booking?.email,
            comment: booking?.comment,
            bookingType: booking?.bookingType,
            hasBonusCertificate: booking?.hasCertificate,
            isFirstGame: booking?.isFirstGame,
            dateOfBooking: booking?.dateOfBooking?.toISOString() ?? new Date(Date.now()).toISOString(),
            isDeleted: false,
        });

        if (!response.ok) {
            toast({
                title: "Error",
                description: "Something went wrong. Please reload page and try again!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Success",
            description: "Booking has been succesfully restored!",
            status: "success",
            duration: 9000,
            isClosable: true,
        });

        loadBookings();
    };

    const handleDeleteBooking = async (booking: Booking) => {
        const response = await adminRepository.createOrUpdateBooking({
            id: booking?.id,
            eventId: booking?.eventId ?? event?.id,
            eventVersion: booking?.eventVersion ?? event?.version,
            teamName: booking?.teamName,
            teamLeaderName: booking?.teamLeaderName,
            teammatesCount: booking?.teammatesCount,
            phoneNumber: booking?.phoneNumber,
            email: booking?.email,
            comment: booking?.comment,
            bookingType: booking?.bookingType,
            hasBonusCertificate: booking?.hasCertificate,
            isFirstGame: booking?.isFirstGame,
            dateOfBooking: booking?.dateOfBooking?.toISOString() ?? new Date(Date.now()).toISOString(),
            isDeleted: true,
        });

        if (!response.ok) {
            toast({
                title: "Error",
                description: "Something went wrong. Please reload page and try again!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Success",
            description: "Booking has been succesfully deleted. You can alwasys restore it if needed.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });

        loadBookings();
    };

    const loadEvents = async (signal?: AbortSignal) => {
        const eventResp = await eventRepository.getAllEvents(showDeleted, signal);
        if (eventResp.ok)
            setEvents(
                eventResp.data?.sort((a, b) => {
                    return b.date.getTime() - a.date.getTime();
                })
            );
    };

    const loadBookings = async (signal?: AbortSignal) => {
        if (event === undefined) return;

        const response = await bookingRepository.getBookingsByEventId(event?.id, showDeleted, signal);
        if (response.ok)
            setBookings(
                response.data?.sort((a, b) => {
                    return b.dateOfBooking.getTime() - a.dateOfBooking.getTime();
                })
            );
    };

    useEffect(() => {
        const controller = new AbortController();
        loadBookings(controller.signal);

        return () => {
            controller.abort();
        };
    }, [event, showDeleted]);

    useEffect(() => {
        const controller = new AbortController();
        loadEvents(controller.signal);

        return () => {
            controller.abort();
        };
    }, []);

    return (
        <>
            <AdminAuthWrapper>
                <VStack gap={7} w="100%">
                    <Flex pl={10} pr={10} pt={2} w="100%" gap={10} align="center" direction="row" justify="left">
                        <Button size="sm" leftIcon={<FiPlus />} onClick={() => handleBookingEdit()} pr={6} pl={5}>
                            Add booking
                        </Button>
                        <Flex align="center" direction="row" justify="space-between" gap={2}>
                            <Text fontSize="sm" whiteSpace="normal">
                                Show deleted
                            </Text>
                            <Switch isChecked={showDeleted} onChange={handleShowDeleted} size="md" colorScheme="red" />
                        </Flex>
                        <Select value={event?.id} onChange={handleEventChange} maxW={700}>
                            <option value=""></option>
                            {events?.map((x) => (
                                <option key={x.id} value={x.id}>{`${x.city.label.en} - ${x.place.name} | ${x.name} 
                            | ${x.date.toLocaleString()}`}</option>
                            ))}
                        </Select>
                    </Flex>
                    <TableContainer w="100%">
                        <Table size="sm" variant="simple" colorScheme="facebook" whiteSpace="normal" align="center">
                            <Thead>
                                <Tr>
                                    <Th whiteSpace="break-spaces">Booking date&time</Th>
                                    <Th>Team name</Th>
                                    <Th>Leader name</Th>
                                    <Th whiteSpace="break-spaces">Teammates count</Th>
                                    <Th>Phone</Th>
                                    <Th>Email</Th>
                                    <Th>Comment</Th>
                                    <Th whiteSpace="break-spaces">First game</Th>
                                    <Th>Has certificate</Th>
                                    <Th>Booking type</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {bookings?.map((x) => {
                                    return (
                                        <Tr
                                            key={x.id}
                                            bg={
                                                x.isDeleted
                                                    ? "red.200"
                                                    : x.bookingType == BookingType.Reserve
                                                    ? "yellow.100"
                                                    : ""
                                            }
                                        >
                                            <Td>{x.dateOfBooking.toLocaleString()}</Td>
                                            <Td>{x.teamName}</Td>
                                            <Td>{x.teamLeaderName}</Td>
                                            <Td textAlign="center">{x.teammatesCount}</Td>
                                            <Td>{x.phoneNumber}</Td>
                                            <Td>{x.email}</Td>
                                            <Td>
                                                <Tooltip label={x.comment}>
                                                    <Box
                                                        maxH={20}
                                                        maxW={100}
                                                        overflow="hidden"
                                                        whiteSpace="nowrap"
                                                        textOverflow="ellipsis"
                                                    >
                                                        {x.comment}
                                                    </Box>
                                                </Tooltip>
                                            </Td>
                                            <Td textAlign="center">
                                                <Center>{x.isFirstGame ? <FiPlusCircle /> : ""}</Center>
                                            </Td>
                                            <Td>{x.hasCertificate ? <FiPlusCircle /> : ""}</Td>
                                            <Td>{x.bookingType == BookingType.Regular ? "Regular" : "Reserve"}</Td>
                                            <Td>
                                                <Menu>
                                                    <MenuButton as={IconButton} icon={<FiMoreVertical />} />
                                                    <MenuList>
                                                        <MenuItem
                                                            icon={<FiEdit />}
                                                            onClick={() => handleBookingEdit(x)}
                                                        >
                                                            Edit
                                                        </MenuItem>
                                                        <MenuDivider />
                                                        {x.isDeleted ? (
                                                            <MenuItem
                                                                icon={<FiRefreshCw />}
                                                                color="green.500"
                                                                onClick={() => handleRestoreBooking(x)}
                                                            >
                                                                Restore
                                                            </MenuItem>
                                                        ) : (
                                                            <MenuItem
                                                                icon={<FiTrash2 />}
                                                                color="red.500"
                                                                onClick={() => handleDeleteBooking(x)}
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        )}
                                                    </MenuList>
                                                </Menu>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </VStack>
                <EditBookingModal
                    onOpen={onOpen}
                    onClose={onClose}
                    isOpen={isOpen}
                    booking={bookingOnEdit}
                    event={event}
                    reloadBookings={loadBookings}
                />
            </AdminAuthWrapper>
        </>
    );
};
