import {
    Button,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    useToast,
    VStack,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FiEdit, FiMoreVertical, FiPlus, FiRefreshCw, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { auth } from "../../common/authRedirect";
import { adminRepository } from "../../domain/admin/adminRepository";
import { City } from "../../domain/city/city";
import { cityRepository } from "../../domain/city/cityRepository";
import { AdminAuthWrapper } from "./adminAuthWrapper";
import { EditCityModal } from "./editCityModal";

export const AdminCities: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [cities, setCities] = useState<City[]>();
    const [cityOnEdit, setCityOnEdit] = useState<City>();
    const [showDeleted, setShowDeleted] = useState(false);

    const handleShowDeleted = (e: SyntheticEvent) => {
        setShowDeleted((e.target as HTMLInputElement).checked);
    };

    const handleCityEdit = (city?: City) => {
        setCityOnEdit(city);
        onOpen();
    };

    const handleCityDelete = async (city: City) => {
        const response = await adminRepository.createOrUpdateCity({
            id: city.id,
            krName: city.label.한국어,
            enName: city.label.en,
            isDeleted: true,
        });

        if (!response.ok) {
            toast({
                title: "Error",
                description: "Something went wrong. Please reload page and try again!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Success",
            description: "City was successfully deleted. You can always resotre it if needed.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });

        loadCities();
    };

    const handleCityRestore = async (city: City) => {
        const response = await adminRepository.createOrUpdateCity({
            id: city.id,
            krName: city.label.한국어,
            enName: city.label.en,
            isDeleted: false,
        });

        if (!response.ok) {
            toast({
                title: "Error",
                description: "Something went wrong. Please reload page and try again!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Success",
            description: "City was successfully restored.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });

        loadCities();
    };

    const loadCities = async (signal?: AbortSignal) => {
        const response = await cityRepository.getAllCities(showDeleted, signal);
        if (response.ok) setCities(response.data);
    };

    useEffect(() => {
        const controller = new AbortController();
        loadCities(controller.signal);
        return () => {
            controller.abort();
        };
    }, [showDeleted]);

    return (
        <>
            <AdminAuthWrapper>
                <VStack>
                    <Flex pl={10} pt={2} w="100%" gap={10} align="center" direction="row" justify="left">
                        <Button leftIcon={<FiPlus />} onClick={() => handleCityEdit()}>
                            Add city
                        </Button>
                        <Flex align="center" direction="row" justify="space-between" gap={2}>
                            <Text fontSize="sm" whiteSpace="normal">
                                Show deleted
                            </Text>
                            <Switch isChecked={showDeleted} onChange={handleShowDeleted} size="md" colorScheme="red" />
                        </Flex>
                    </Flex>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>En name</Th>
                                    <Th>Kr name</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {cities?.map((city) => {
                                    return (
                                        <Tr key={city.id} bg={city.isDeleted ? "red.200" : ""}>
                                            <Td>{city.label.en}</Td>
                                            <Td>{city.label.한국어}</Td>
                                            <Td>
                                                <Menu>
                                                    <MenuButton as={IconButton} icon={<FiMoreVertical />} />
                                                    <MenuList>
                                                        <MenuItem
                                                            icon={<FiEdit />}
                                                            onClick={() => handleCityEdit(city)}
                                                        >
                                                            Edit
                                                        </MenuItem>
                                                        <MenuDivider />
                                                        {city.isDeleted ? (
                                                            <MenuItem
                                                                icon={<FiRefreshCw />}
                                                                color="green.500"
                                                                onClick={() => handleCityRestore(city)}
                                                            >
                                                                Restore
                                                            </MenuItem>
                                                        ) : (
                                                            <MenuItem
                                                                icon={<FiTrash2 />}
                                                                color="red.500"
                                                                onClick={() => handleCityDelete(city)}
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        )}
                                                    </MenuList>
                                                </Menu>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </VStack>
                <EditCityModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    city={cityOnEdit}
                    loadCities={loadCities}
                />
            </AdminAuthWrapper>
        </>
    );
};
