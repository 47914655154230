import { Box, Button, Flex, Grid, GridItem, Heading, Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/languageContext";
import { intl, IntlKey, Languages } from "../../intl-languages";

export const HeroMobile: React.FC = () => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return (
        <Box
            minH={150}
            bg="white"
            pt={4}
            backgroundImage="url('/assets/hero_mobile.png')"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            color="whiteAlpha.900"
            bgColor="quiz_purple.50"
        >
            <Grid w="100%" templateColumns="repeat(4, 1fr)" templateRows="repeat(6, 1fr)">
                <GridItem colSpan={3} rowSpan={1}></GridItem>
                <GridItem colSpan={3} rowSpan={5}>
                    <VStack pl="10%" gap={2}>
                        <Heading size="2xl">{intl.heroHeading[langKey]}</Heading>
                        <Flex justify="left">
                            <Text w="80%" fontSize="sm">
                                {intl.heroText[langKey]}
                            </Text>
                        </Flex>
                        <Box w="100%">
                            <Link to="/schedule">
                                <Button
                                    rightIcon={<FiChevronRight />}
                                    variant="solid"
                                    bg="#8900F4"
                                    bgGradient="linear(to-br, #F400BF, #8900F4)"
                                    _hover={{ bg: "#8900F4" }}
                                    _active={{ bg: "#F400BF" }}
                                >
                                    Play
                                </Button>
                            </Link>
                        </Box>
                    </VStack>
                </GridItem>
            </Grid>
        </Box>
    );
};
