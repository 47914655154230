import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Text,
    Tbody,
    Flex,
    IconButton,
    VStack,
    Button,
    Box,
    Switch,
    Td,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { FiEdit, FiMoreVertical, FiPlus, FiRefreshCw, FiTrash2 } from "react-icons/fi";
import { adminRepository } from "../../domain/admin/adminRepository";
import { City } from "../../domain/city/city";
import { cityRepository } from "../../domain/city/cityRepository";
import { Event } from "../../domain/event/event";
import { eventRepository } from "../../domain/event/eventRepository";
import { AdminAuthWrapper } from "./adminAuthWrapper";
import { EditEventModal } from "./editEventModal";

export const AdminEvent: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [events, setEvents] = useState([] as Event[]);
    const [showDeleted, setShowDeleted] = useState(false);
    const [eventOnEdit, setEventOnEdit] = useState<Event>();
    const [cities, setCities] = useState<City[]>();

    const loadCities = async (signal: AbortSignal) => {
        const response = await cityRepository.getAllCities(false, signal);
        if (response.ok && response.data) setCities(response.data);
    };

    const loadEvents = async (showDeleted: boolean, signal?: AbortSignal) => {
        const eventsRes = await eventRepository.getAllEvents(showDeleted, signal);

        if (eventsRes.ok && eventsRes.data !== undefined) {
            setEvents(
                eventsRes.data.sort((a, b) => {
                    return b.date.getTime() - a.date.getTime();
                })
            );
        }
    };

    const reloadEvents = async () => {
        await loadEvents(showDeleted);
    };

    const handleShowDeleted = (e: SyntheticEvent) => {
        setShowDeleted((e.target as HTMLInputElement).checked);
    };

    const handleEventEdit = (event?: Event) => {
        setEventOnEdit(event);
        onOpen();
    };

    const handleDeleteEvent = async (event?: Event) => {
        const response = await adminRepository.createOrUpdateEvent({
            id: event?.id,
            cityId: event?.city.id,
            placeId: event?.place.id,
            name: event?.name,
            date: event?.date.toISOString(),
            bookingStartDate: event?.bookingStartDate.toISOString(),
            description: event?.description,
            maxBookingCount: event?.maxBookingCount,
            maxFallbackPlacesCount: event?.maxFallbackPlacesCount,
            maxTeammatesCount: event?.maxTeammatesCount,
            price: event?.price,
            photoGalleryUrl: event?.photoGalleryUrl,
            photoCoverUrl: event?.photoCoverUrl,
            version: event?.version,
            isDeleted: true,
        });

        if (!response.ok) {
            toast({
                title: "Error",
                description: "Something went wrong. Please reload page and try again!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Success",
            description:
                "Event has been succesfully marked as deleted. It won't be shown to users. You can always restore it if needed.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });
        reloadEvents();
    };

    const handleRestoreEvent = async (event?: Event) => {
        const response = await adminRepository.createOrUpdateEvent({
            id: event?.id,
            cityId: event?.city.id,
            placeId: event?.place.id,
            name: event?.name,
            date: event?.date.toISOString(),
            bookingStartDate: event?.bookingStartDate.toISOString(),
            description: event?.description,
            maxBookingCount: event?.maxBookingCount,
            maxFallbackPlacesCount: event?.maxFallbackPlacesCount,
            maxTeammatesCount: event?.maxTeammatesCount,
            price: event?.price,
            photoGalleryUrl: event?.photoGalleryUrl,
            photoCoverUrl: event?.photoCoverUrl,
            version: event?.version,
            isDeleted: false,
        });

        if (!response.ok) {
            toast({
                title: "Error",
                description: "Something went wrong. Please reload page and try again!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        toast({
            title: "Success",
            description: "Event has been succesfully restored.",
            status: "success",
            duration: 9000,
            isClosable: true,
        });
        reloadEvents();
    };

    useEffect(() => {
        const controller = new AbortController();
        loadCities(controller.signal);

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        loadEvents(showDeleted, controller.signal);

        return () => {
            controller.abort();
        };
    }, [showDeleted]);

    useEffect(() => {
        const controller = new AbortController();
        return () => {
            controller.abort();
        };
    }, [events]);

    return (
        <>
            <AdminAuthWrapper>
                <VStack w="100%">
                    <Flex pl={10} pt={2} w="100%" gap={10} align="center" direction="row" justify="left">
                        <Button size="sm" leftIcon={<FiPlus />} onClick={() => handleEventEdit()}>
                            Add event
                        </Button>
                        <Flex align="center" direction="row" justify="space-between" gap={2}>
                            <Text fontSize="sm" whiteSpace="normal">
                                Show deleted
                            </Text>
                            <Switch isChecked={showDeleted} onChange={handleShowDeleted} size="md" colorScheme="red" />
                        </Flex>
                    </Flex>
                    <TableContainer>
                        <Table size="sm" variant="simple" colorScheme="facebook" whiteSpace="normal" align="center">
                            <Thead>
                                <Tr>
                                    <Th>City</Th>
                                    <Th>Place</Th>
                                    <Th>Date and time</Th>
                                    <Th>Name</Th>
                                    <Th>Description</Th>
                                    <Th>Max teammates count</Th>
                                    <Th>Max booking count</Th>
                                    <Th>Booking count</Th>
                                    <Th>Max reserve count</Th>
                                    <Th>Reserve count</Th>
                                    <Th>Price</Th>
                                    <Th>Date of booking start</Th>
                                    <Th>Photo album url</Th>
                                    <Th>Photo cover URL</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {events.map((event) => {
                                    const date = event.date;
                                    return (
                                        <Tr key={event.id} bg={event.isDeleted ? "red.200" : ""}>
                                            <Td>{event.city.label.en}</Td>
                                            <Td>{event.place.name}</Td>
                                            <Td whiteSpace="nowrap">{date.toLocaleString()}</Td>
                                            <Td>{event.name}</Td>
                                            <Td>
                                                <Tooltip label={event.description}>
                                                    <Box
                                                        maxH={20}
                                                        maxW={100}
                                                        overflow="hidden"
                                                        whiteSpace="nowrap"
                                                        textOverflow="ellipsis"
                                                    >
                                                        {event.description}
                                                    </Box>
                                                </Tooltip>
                                            </Td>
                                            <Td>{event.maxTeammatesCount}</Td>
                                            <Td>{event.maxBookingCount}</Td>
                                            <Td>{event.maxBookingCount - event.placesLeft}</Td>
                                            <Td>{event.maxFallbackPlacesCount}</Td>
                                            <Td>{event.maxFallbackPlacesCount - event.fallbackPlacesLeft}</Td>
                                            <Td>{event.price}</Td>
                                            <Td whiteSpace="nowrap">{event.bookingStartDate.toLocaleString()}</Td>
                                            <Td>
                                                <Tooltip label={event.photoGalleryUrl}>
                                                    <Box
                                                        maxH={20}
                                                        maxW={100}
                                                        overflow="hidden"
                                                        whiteSpace="nowrap"
                                                        textOverflow="ellipsis"
                                                    >
                                                        {event.photoGalleryUrl}
                                                    </Box>
                                                </Tooltip>
                                            </Td>
                                            <Td>
                                                <Tooltip label={event.photoCoverUrl}>
                                                    <Box
                                                        maxH={20}
                                                        maxW={100}
                                                        overflow="hidden"
                                                        whiteSpace="nowrap"
                                                        textOverflow="ellipsis"
                                                    >
                                                        {event.photoCoverUrl}
                                                    </Box>
                                                </Tooltip>
                                            </Td>
                                            <Td>
                                                <Menu>
                                                    <MenuButton as={IconButton} icon={<FiMoreVertical />} />
                                                    <MenuList>
                                                        <MenuItem
                                                            icon={<FiEdit />}
                                                            onClick={() => handleEventEdit(event)}
                                                        >
                                                            Edit
                                                        </MenuItem>
                                                        <MenuDivider />
                                                        {event.isDeleted ? (
                                                            <MenuItem
                                                                icon={<FiRefreshCw />}
                                                                color="green.500"
                                                                onClick={() => handleRestoreEvent(event)}
                                                            >
                                                                Restore
                                                            </MenuItem>
                                                        ) : (
                                                            <MenuItem
                                                                icon={<FiTrash2 />}
                                                                color="red.500"
                                                                onClick={() => handleDeleteEvent(event)}
                                                            >
                                                                Delete
                                                            </MenuItem>
                                                        )}
                                                    </MenuList>
                                                </Menu>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </VStack>
                <EditEventModal
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                    event={eventOnEdit}
                    cities={cities}
                    reloadEvents={reloadEvents}
                />
            </AdminAuthWrapper>
        </>
    );
};
