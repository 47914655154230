import { City } from "../city/city";
import { Place } from "../place/place";
import { EventDto } from "./dto/eventDto";

export type Event = {
    id: string;
    name: string;
    date: Date;
    bookingStartDate: Date;
    description: string;
    price: number;
    placesLeft: number;
    fallbackPlacesLeft: number;
    maxBookingCount: number;
    maxFallbackPlacesCount: number;
    maxTeammatesCount: number;
    place: Place;
    city: City;
    photoGalleryUrl: string;
    photoCoverUrl: string;
    isDeleted: boolean;
    version: string;
};

export const getEventFromDto = (dto: EventDto): Event => {
    return {
        id: dto.id,
        description: dto.description,
        date: new Date(dto.date),
        bookingStartDate: new Date(dto.bookingStartDate),
        name: dto.name,
        price: dto.price,
        maxFallbackPlacesCount: dto.maxFallbackPlacesCount,
        fallbackPlacesLeft: dto.fallbackPlacesLeft,
        maxBookingCount: dto.maxBookingCount,
        maxTeammatesCount: dto.maxTeammatesCount,
        placesLeft: dto.placesLeft,
        place: {
            id: dto.place?.id ?? "",
            address: dto.place?.address ?? "",
            geoTagUrl: dto.place?.geoTagUrl ?? "",
            name: dto.place?.name ?? "",
            isDeleted: dto.place?.isDeleted ?? false,
            cityId: dto.place?.cityId ?? "",
        },
        city: {
            id: dto.city?.id ?? "",
            label: {
                en: dto.city?.enName ?? "",
                한국어: dto.city?.krName ?? "",
            },
            isDeleted: dto.city?.isDeleted ?? false,
        },
        photoGalleryUrl: dto.photoGalleryUrl,
        photoCoverUrl: dto.photoCoverUrl,
        isDeleted: dto.isDeleted,
        version: dto.version,
    };
};
